import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        reg_create_account: "Create your account",
        reg_continue_with: "Sign in with",
        reg_continue: "Sign in",
        reg_new_in: "New to Osmos?",
        reg_join_now: "Join now",
        reg_continue_with_email: "Continue with email",
        reg_your_email: "Your e-mail",
        reg_send_link: "Send the magic link",
        reg_check_email: "Check your email. We sent the magic link for authorization",
        reg_open_mailbox: "Open Mailbox",
        reg_ok_got_it: `Ok, I got it!`,
        reg_auth_timeout: "Mail authorization time is out",
        reg_send_again: "Send another link",
        reg_error: "Something went wrong",
        reg_try_again: "Please, try again",
        reg_reload_page: "Reload the page",
        sign_up: "Sign up",
        sign_in: "Sign in",
        fname: "First name",
        fname_ph: "Your first name",
        lname: "Last name",
        lname_ph: "Your last name",
        next: "Next",
        skip: "Skip",
        or: "or",
        settings: "Settings",
        settings_support: "Support",
        settings_support_email: "Email us if you have any questions",
        settings_profile_settings: "Profile settings",
        settings_profile_email_prefs: "Email preferences",
        settings_profile_email_pref_1: "Notify me about my matches in my email",
        settings_profile_email_pref_2: "Keep me updated with news about the project",
        settings_profile_email_pref_3: "Get platform updates",
        settings_profile_save: "Save changes",
        settings_profile_email_unsub: "Unsubscribe from all marketing emails",
        settings_profile_delete: "Delete account",
        settings_profile_delete_reason_0: "I have a duplicate account",
        settings_profile_delete_reason_1: "I'm getting too many emails",
        settings_profile_delete_reason_2: "I'm not getting any value from my membership",
        settings_profile_delete_reason_3: "I have a privacy concern",
        settings_profile_delete_reason_4: "I'm receiving unwanted contact",
        settings_profile_delete_reason_5: "Other",
        settings_profile_delete_hint_1: "You have an active subscription. Your subscription will be automatically renewed on",
        settings_profile_delete_hint_2: "You can cancel the auto-renewal at any time before your renewal date, however, no refunds are available within the active subscription period.",
        settings_profile_delete_final_1: "Thanks for your feedback",
        settings_profile_delete_final_2: "we are constantly improving",
        settings_profile_delete_final_3: "After deleting your account, you will no longer be able to access it. Your subscription will be cancelled, and you won't be charged for the upcoming period. However, we do not provide refunds for the remaining subscription period after you delete your account.",
        settings_logout: "Log out",
        settings_logout_stay: "Stay logged in",
        settings_logout_hint: "By logging out, you will be signed out of your current session",
        read_more: "Read more",
        nothing_found: "No results found",
        ob_onlylatin: "To broaden your match possibilities, ensure you only use the English language",
        ob_header_common: "Introduce yourself",
        ob_notify_matches: "Notify me about my matches in my email",
        ob_concent: "I consent to the",
        ob_processing_data: "processing of my personal data",
        ob_photo_header: "Add your photo",
        ob_photo_hint: "People prefer to build connections with those who have a photo",
        ob_add_from_lib: "Add from library",
        ob_intro_header: "Introduce yourself in a way that makes other professionals interested to connect with you.",
        ob_intro_hint: "What are you working on right now? What do you know better then anyone else? What can you help others with?",
        ob_intro_short_1: "Your bio seems a bit short, could you add some more details?",
        ob_intro_short_2: "Heading in the right direction! Yet, we could benefit from more details.",
        ob_intro_short_ok: "Your bio looks great, thank you for sharing!",
        ob_industry_header: "Choose industry you're in",
        industry_ph: "Start typing", 
        industry_ph_40: "Search in 40+ industries",
        ob_occupation_header: "Add more details in your profile",
        ob_occupation_company: "Company",
        occupation_company_ph: "Enter the name of your company",
        ob_occupation_role: "Role",
        occupation_role_ph: "Enter the role in the company",
        ob_occupation_city: "Your city",
        city_ph: "Enter the city you live in",
        match: "Match",
        match_are_you_ready: "Are you ready for 1:1\u00A0conversation?",
        match_until: "Let us know by 23:59 Sunday",
        match_start: "Yes, I'm in",
        match_pause: "Pause matching",
        match_next_in: "Your next match is in",
        match_notify: "We'll notify you by email.",
        match_arrange: "Arrange a meeting",
        match_feedback: "Share feedback",
        match_email_hint_1: "If you can’t find our email, check your spam folder.",
        match_email_hint_2: "And please mark ",
        match_email_hint_3: " as ‘not\u00A0spam’",
        match_rate_title: "Rate profiles' relevance to get better matches!",
        match_rate_description_1: "Each profile you rate helps our algorithm become more accurate.",
        match_rate_description_2: "Simply choose how relevant each professional is to your goals.",
        match_rate_not_relevant: "Not relevant",
        match_rate_relevant: "Highly relevant",
        match_select_title: "Pick the most relevant profile",
        match_select_description_1: "Take a quick 2-minute test to help us understand your goals.",
        match_select_description_2: "We'll connect you with the ideal expert for your unique needs.",
        match_about: "About",
        match_requests: "Requests",
        match_projects: "Projects",
        feed: "Feed",
        feed_filter: "Filter requests",
        feed_filter_by_industry: "Filter by industry",
        feed_similar: "Similar to you",
        feed_end_1: "You've scrolled to the end of your feed.",
        feed_end_2: "Please come back later.",
        chat: "Chat",
        chat_empty: "No chats yet",
        chat_empty_description_1: "It seems you don’t have any chats right now.",
        chat_empty_description_2: "To start a conversation, wait for a match or help someone out!",
        go_to_feed: "Go to feed",
        profile: "Profile",
        profile_edit: "Edit profile",
        profile_settings: "Account settings",
        profile_tell_about: "Tell about yourself",
        profile_add_about: "Add about",
        profile_add_project: "Add your project",
        profile_add_request: "Add request",
        profile_add_project_description: "Projects help other people understand who you are.",
        profile_add_a_project: "Add a project",
        projects: "Projects",
        projects_add: "Add new project",
        projects_update: "Update projects",
        projects_url: "URL",
        projects_url_ph: "Link to your project website",
        projects_title: "Title",
        projects_title_ph: "Enter the title of the project",
        projects_description: "Description",
        projects_description_ph: "Tell about your project",
        projects_add_btn: "Add project",
        projects_update_btn: "Update project",
        confirm_header: "This action can’t be undone",
        confirm_exit_text: "Exit without saving changes?",
        exit: "Exit",
        cancel: "Cancel",
        ok: "Ok",
        request_type_0: "Share experience",
        request_type_1: "Find a job",
        request_type_2: "Hire",
        request_type_3: "Find clients",
        request_type_4: "Raise funds",
        request_type_5: "Invest",
        request_type_6: "Find experts",
        request_type_7: "Find partners",
        request_type_8: "Network",
    },
    [LOCALES.RUSSIAN]: {
        reg_create_account: "Создать аккаунт",
        reg_continue_with: "Продолжить через",
        reg_continue: "Продолжить",
        reg_new_in: "Новичок в Osmos?",
        reg_join_now: "Войти",
        reg_continue_with_email: "Продолжить через почту",
        reg_your_email: "Ваш e-mail",
        reg_send_link: "Отправить волшебную ссылку",
        reg_check_email: "Загляните в почтовый ящик. Мы отправили вам волшебную ссылку для авторизации",
        reg_open_mailbox: "В почту",
        reg_ok_got_it: `Отлично!`,
        reg_auth_timeout: "Время для авторизации истекло",
        reg_send_again: "Отправить ещё раз",
        reg_error: "Что-то пошло не так",
        reg_try_again: "Попробуйте позже",
        reg_reload_page: "Перезагрузить",
        sign_up: "Зарегистрироваться",
        sign_in: "Войти",
        fname: "Имя",
        fname_ph: "Ваше имя",
        lname: "Фамилия",
        lname_ph: "Ваша фамилия",
        next: "Далее",
        skip: "Пропустить",
        or: "или",
        settings: "Настройки",
        settings_support: "Помощь",
        settings_support_email: "Напишите нам, если у вас возникли вопросы",
        settings_profile_settings: "Настройки аккаунта",
        settings_profile_email_prefs: "Оповещения",
        settings_profile_email_pref_1: "Сообщать о новых собеседниках по почте",
        settings_profile_email_pref_2: "Присылать новости проекта",
        settings_profile_email_pref_3: "Оповещать об обновлениях в сервисе",
        settings_profile_save: "Сохранить изменения",
        settings_profile_email_unsub: "Отписаться от всех маркетинговых рассылок",
        settings_profile_delete: "Удалить аккаунт",
        settings_profile_delete_reason_0: "У меня есть второй аккаунт",
        settings_profile_delete_reason_1: "Я получаю слишком много писем",
        settings_profile_delete_reason_2: "I'm not getting any value from my membership",
        settings_profile_delete_reason_3: "I have a privacy concern",
        settings_profile_delete_reason_4: "I'm receiving unwanted contact",
        settings_profile_delete_reason_5: "Другое",
        settings_profile_delete_hint_1: "Ваша подписка ещё активна. Активный период заканчивается",
        settings_profile_delete_hint_2: "Вы можете отменить автоматическое продление подписки в любой момент до даты очередного списания, тем не менее, no refunds are available within the active subscription period.",
        settings_profile_delete_final_1: "Спасибо за обратную связь",
        settings_profile_delete_final_2: "мы постоянно становимся лучше",
        settings_profile_delete_final_3: "After deleting your account, you will no longer be able to access it. Your subscription will be cancelled, and you won't be charged for the upcoming period. However, we do not provide refunds for the remaining subscription period after you delete your account.",
        settings_logout: "Выйти",
        settings_logout_stay: "Остаться",
        settings_logout_hint: "By logging out, you will be signed out of your current session",
        read_more: "Больше",
        nothing_found: "Ничего не найдено",
        ob_onlylatin: "To broaden your match possibilities, ensure you only use the English language",
        ob_header_common: "Давайте знакомиться!",
        ob_notify_matches: "Сообщать о новых собеседниках по почте",
        ob_concent: "Даю согласие на",
        ob_processing_data: "обработку моих персональных данных",
        ob_photo_header: "Добавьте фото",
        ob_photo_hint: "Людям проще начать разговор с теми, кого они видят",
        ob_add_from_lib: "Загрузить",
        ob_intro_header: "Опишите себя так, чтобы другим профессионалам было интересно с вами связаться.",
        ob_intro_hint: "Над чем вы сейчас работаете? Что вы знаете или умеете лучше всех? Какую помощь вы можете предложить другим?",
        ob_intro_short_1: "Коротковато для биографии! Добавите больше деталей?",
        ob_intro_short_2: "Движетесь в нужном направлении! Добавьте еще деталей, и будет идеально.",
        ob_intro_short_ok: "Выглядит отлично! Спасибо, что поделились!",
        ob_industry_header: "Выберите свою индустрию",
        industry_ph: "Начните ввод", 
        industry_ph_40: "Начните ввод",
        ob_occupation_header: "Добавьте в профиль больше деталей",
        ob_occupation_company: "Компания",
        occupation_company_ph: "Введите название вашей компании",
        ob_occupation_role: "Позиция",
        occupation_role_ph: "Кем вы работаете?",
        ob_occupation_city: "Город",
        city_ph: "Введите название города, в котором живете",
        match: "Собеседники",
        match_are_you_ready: "Вы готовы к новому знакомству и разговору\u00A01:1?",
        match_until: "Сообщите нам до 23:59 воскресенья",
        match_start: "Я в деле!",
        match_pause: "Остановить",
        match_next_in: "Ваш следующий собеседник",
        match_notify: "Мы сообщим вам письмом на почту.",
        match_arrange: "К встрече",
        match_feedback: "Оставить отзыв",
        match_email_hint_1: "Если вы не нашли письма, проверьте папку со спамом.",
        match_email_hint_2: "Пожалуйста, отметьте ",
        match_email_hint_3: " как ‘не\u00A0спам’",
        match_rate_title: "Оцените эти профили чтобы получать подходящих собеседников!",
        match_rate_description_1: "Каждый оценённый вами профиль помогает нашим алгоритмам работать лучше.",
        match_rate_description_2: "Просто укажите, насколько эти профессионалы подходят для ваших целей.",
        match_rate_not_relevant: "Не подходит",
        match_rate_relevant: "Весьма подходит",
        match_select_title: "Выберите более подходящий профиль",
        match_select_description_1: "Пройдите быстрый опрос, чтобы мы лучше поняли ваши цели.",
        match_select_description_2: "Мы соединим вас с экспертом, который идеален для вас.",
        match_about: "About",
        match_requests: "Requests",
        match_projects: "Projects",
        feed: "Лента",
        feed_filter: "Фильтр",
        feed_filter_by_industry: "По индустриям",
        feed_similar: "Для вас",
        feed_end_1: "Вы долистали до конца ленты.",
        feed_end_2: "Пожалуйста, вернитесь позднее.",
        chat: "Чат",
        chat_empty: "Чатов пока нет",
        chat_empty_description_1: "Кажется, у вас пока что нет ни одного чата.",
        chat_empty_description_2: "Чтобы начать общение, дождитесь, пока вас соединят с собеседником, или ответьте на интересующий вас запрос в\u00A0ленте!",
        go_to_feed: "К ленте",
        profile: "Профиль",
        profile_edit: "Редактировать профиль",
        profile_settings: "Настройки аккаунта",
        profile_add_about: "Добавь описание",
        profile_tell_about: "Расскажи о себе",
        profile_add_project: "Добавить проект",
        profile_add_request: "Добавить запрос",
        profile_add_project_description: "Проекты помогают другим людям понять, кто вы",
        profile_add_a_project: "Добавить проект",
        projects: "Проекты",
        projects_add: "Добавить новый проект",
        projects_update: "Редактировать проект",
        projects_url: "URL",
        projects_url_ph: "Ссылка на сайт вашего проекта",
        projects_title: "Название",
        projects_title_ph: "Введите название проекта",
        projects_description: "Описание",
        projects_description_ph: "Расскажите о проекте подробнее",
        projects_add_btn: "Добавить проект",
        projects_update_btn: "Обновить проект",
        confirm_header: "Это действие нельзя отменить",
        confirm_exit_text: "Выйти, не сохранив изменения?",
        exit: "Выйти",
        ok: "Ок",
        cancel: "Отмена",
        request_type_0: "Обмен опытом",
        request_type_1: "Поиск работы",
        request_type_2: "Поиск сотрудников",
        request_type_3: "Поиск клиентов",
        request_type_4: "Поиск инвестиций",
        request_type_5: "Инвестирование",
        request_type_6: "Поиск экспертов",
        request_type_7: "Поиск партнёров",
        request_type_8: "Нетворкинг",
    }
}