import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { 
    ChatBlack, 
    ChatWhite, 
    CopyBlack,  
    ExitBlack,  
    GearBlack, 
    HumansBlack, 
    HumansWhite, 
    LinkBlack,   
    ListBlack,  
    ListWhite,  
    NotificationBlack, 
    NotificationWhite, 
    OkWhite, 
    OsmosM, 
    OsmosMBlack, 
    OsmosO, 
    OsmosOBlack, 
    OsmosS, 
    OsmosSBlack, 
    PageListBlack,
    Plug1, 
    Plug2, 
    Plug3, 
    PlusBlack, 
    PlusWhite, 
    PostBlack,  
    ProtectBlack, 
    SettingsBlack, 
    SettingsWhite, 
    SupportBlack,
    TrashBlack,
    TailTRGreen,
    TailTLGreen,
    DocWhite,
    DocBlack,
    XBlack,
    LinkedInBlack,
    LinkedInWhite,
    XWhite,
    LensBlack,
    HistoryGray,
    PHLogo,
} from "./Imgs";
import { 
    Capitals, 
    CommunityLogo, 
    CreateHSLColor, 
    DateTimeFromString, 
    GoTo, 
    LogOut,
    LogoLetters, 
    MakeNiceURL, 
    MakeProperURL, 
    MakeValidURL, 
    ProfileStyles, 
    ScreenColorsDict, 
    SetDict, 
    Sticky, 
    Strings 
} from "./Data";
import { 
    EmailPrefsUpdate, 
    InviteTokenBurn, 
    InviteTokenUse, 
    NotificationsRead, 
    ProfileDelete, 
    SearchRequest,
    Subscribe 
} from "../Requests";
import {
    PopUp,
    SettingsPopupRight,
    PopupSignInMobile,
    PopupNotifications,
} from "./Popups";
import {
    MessageCardMobile
} from "../Profile/RequestCards";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { PrivacyPolicy } from "./UserAgreement";

import QRCode from "react-qr-code";
import { GlobalContext, ProfileContext } from "./Contexts";
import { FormattedMessage } from "react-intl";

const TopBar = (props) => {
    const [ cookie ] = useCookies(['user_id_cookie', 'no_sign_in_cookie']);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    // const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();

    const [ newInvite, setNewInvite ] = useState();  
    const [ newQRInvite, setNewQRInvite ] = useState(); 
    const [ newInviteRequest, setNewInviteRequest ] = useState();  
    const [ newQRInviteRequest, setNewQRInviteRequest ] = useState(); 
    
    const [ signInPopup, setSignInPopup ] = useState(props.share && !cookie[`user_id_cookie`] && !props.hideLogIn && !cookie[`no_sign_in_cookie`]);
    const [ invites, setInvites ] = useState()

    useEffect(() => {
        props.setProfile(val => (val?.invites && invites) ? SetDict(val, ['invites'], invites) : val);
    }, [invites])

    useEffect(() => {
        setInvites(props.profile?.invites ?? null);
    }, [props.profile?.invites ?? null]) 

    useEffect(() => {
        setEmailPrefs({
            projectUpdates: {
                enabled: !!props.profile?.emails_project_news,
                title: `Keep me updated with news about the project`,
            },
            platformUpdates: {
                enabled: !!props.profile?.emails_platform_updates,
                title: `Get platform updates`,
            },
            calendarUpdates: {
                enabled: !!props.profile?.emails_matches_calendar_reminders,
                title: `Notify me about my matches in my email`,
            },
            allUpdates: {
                enabled: true,
                title: ``,
            },
        })
    }, [props.profile])

    useEffect(() => {
        if (newInvite?.token) {
            if (window.isSecureContext) {
                navigator.share({
                    title: "Osmos",
                    url: `${window.location.origin}?code=${newInvite?.token}`,
                }).then(() => {                    
                    navigator.clipboard.writeText(`${window.location.origin}?code=${newInvite?.token}`)
                    .then(() => {;
                        if (document.querySelector(`#copied-to-clipboard-label`)) {
                            setCopiedToClipboardLabel(true);
                            // document.querySelector(`#copied-to-clipboard-label`).hidden = false;
                        }
                    })
                })
            }

            setInvites(val => SetDict(val, [`pending`], [newInvite].concat(val.pending)));
            setInvites(val => SetDict(val, [`available`], val.available - 1));
        }
    }, [newInvite]); 

    useEffect(() => {
        if (newInviteRequest?.token) {
            if (window.isSecureContext) {
                navigator.share({
                    title: "Osmos",
                    url: `${window.location.origin}/invite/${newInviteRequest?.token}`,
                }).then(() => {                    
                    navigator.clipboard.writeText(`${window.location.origin}/invite/${newInviteRequest?.token}`)
                    .then(() => {;
                        if (document.querySelector(`#copied-to-clipboard-label`)) {
                            setCopiedToClipboardLabel(true);
                            // document.querySelector(`#copied-to-clipboard-label`).hidden = false;
                        }
                    })
                })
            }

            setInvites(val => SetDict(val, [`pending`], [newInvite].concat(val.pending)));
            setInvites(val => SetDict(val, [`available`], val.available - 1));
        }
    }, [newInviteRequest]);

    useEffect(() => {
        if (newQRInvite?.token) {
            if (window.isSecureContext) {                  
                navigator.clipboard.writeText(`${window.location.origin}?code=${newInvite?.token}`)
                .then(() => {;
                    if (document.querySelector(`#copied-to-clipboard-label`)) {
                        setCopiedToClipboardLabel(true);
                        // document.querySelector(`#copied-to-clipboard-label`).hidden = false;
                    }
                })
            }

            setInvites(val => SetDict(val, [`pending`], [newInvite].concat(val.pending)));
            setInvites(val => SetDict(val, [`available`], val.available - 1));
        }
    }, [newQRInvite]);

    useEffect(() => {
        if (newQRInviteRequest?.token) {
            if (window.isSecureContext) {                  
                navigator.clipboard.writeText(`${window.location.origin}/invite/${newInviteRequest?.token}`)
                .then(() => {;
                    if (document.querySelector(`#copied-to-clipboard-label`)) {
                        setCopiedToClipboardLabel(true);
                        // document.querySelector(`#copied-to-clipboard-label`).hidden = false;
                    }
                })
            }

            setInvites(val => SetDict(val, [`pending`], [newInvite].concat(val.pending)));
            setInvites(val => SetDict(val, [`available`], val.available - 1));
        }
    }, [newQRInviteRequest]);

    const sizes = [ `4px`, `6px`, `9px`, `15px`, `24px`, `15px`, `9px`, `6px`, `4px` ]
    const states = [ 4, 3, 2, 1, 0, 1, 2, 3 ]

    const logoStep = 1;

    const [ showNotifications, setShowNotifications ] = useState(false);
    const [ showSettings, setShowSettings ] = useState(false);
    const [ showSearch, setShowSearch ] = useState(false);

    const searchInput = useRef();
    const [ timer, setTimer ] = useState();
    const [ searchData, setSearchData ] = useState();
    const [ openSearch, setOpenSearch ] = useState(false);

    useEffect(() => {
        if (!showSearch) {
            setTimeout(() => {
                if (searchInput.current) {                     
                    searchInput.current.value = context.globalSearch ?? ""; 
                }
                setSearchData(undefined);
                setOpenSearch(false);
            }, 500)
        }
    }, [showSearch])

    const [ select, setSelect ] = useState(Strings()?.delete_reasons ?? [
        {
            title: `I have a duplicate account`,
        },
        {
            title: `I’m getting too many emails`,
        },
        {
            title: `I’m not getting any value from my membership`,
        },
        {
            title: `I have a privacy concern`,
        },
        {
            title: `I’m receiving unwanted contact`,
        },
        {
            title: `Other`,
        },
    ]);

    const [ settingsPage, setSettingsPage ] = useState(0);
    const [ settingsPopupPage, setSettingsPopupPage ] = useState(0);
    const [ settingsPopup, setSettingsPopup ] = useState(false);
    const [ settingsClosePopup, setSettingsClosePopup ] = useState(true);

    useEffect(() => {
        setSettingsClosePopup(!settingsPopup);
    }, [settingsPopup])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `smooth`,
        });
        setTimeout(() => {
            document.body.style.overflow = (showNotifications || showSettings) ? 'hidden' : null;
        }, (showNotifications || showSettings) ? 500 : 0);
        if (!showNotifications) {
            if (showSettings) {
                
            } else {            
                setTimeout(() => {
                    setSettingsPage(0);
                    setDeleteReason('');
                    setSelect(Strings()?.delete_reasons ?? [
                        {
                            title: `I have a duplicate account`,
                        },
                        {
                            title: `I’m getting too many emails`,
                        },
                        {
                            title: `I’m not getting any value from my membership`,
                        },
                        {
                            title: `I have a privacy concern`,
                        },
                        {
                            title: `I’m receiving unwanted contact`,
                        },
                        {
                            title: `Other`,
                        },
                    ]);
                }, 500);
            }
        }

        if (showNotifications) {
            NotificationsRead(`all`, () => {
                (context.notifications ?? []).forEach((elem, index) => {
                    context.setNotifications(val => SetDict(val, [index, `isRead`], true));
                })
            })
        }
    }, [showNotifications, showSettings])

    const otherDescription = useRef();

    const [ deleteReason, setDeleteReason ] = useState('');

    // const testNotification = () => {
        // addNotification({
        //     title: 'Notification',
        //     subtitle: 'This is a subtitle',
        //     message: 'You have new OSMOS notifications',
        //     native: true,
        //     duration: 10000,
        //     closeButton: 'Close',
        //     icon: '/logo192.png',
        //     onClick: () => {
        //         setShowNotifications(true);
        //     },
        // });
    // };
    
    const [ inviteRequestId, setInviteRequestId ] = useState();

    // function requestPermission() {
    //     Notification.requestPermission((result) => {
    //     })
    // }

    const [ copiedToClipboardLabel, setCopiedToClipboardLabel ] = useState(false);

    useEffect(() => {
        const header = document.querySelector(`#settings-header-popup-content`)
        if (header) {
            header.style.boxShadow = null
        }
        setCopiedToClipboardLabel(false);
    }, [settingsPopupPage])

    const [ emailPrefs, setEmailPrefs ] = useState({
        projectUpdates: {
            enabled: !!props.profile?.emails_project_news,
            title: `Keep me updated with news about the project`,
        },
        platformUpdates: {
            enabled: !!props.profile?.emails_platform_updates,
            title: `Get platform updates`,
        },
        calendarUpdates: {
            enabled: !!props.profile?.emails_matches_calendar_reminders,
            title: `Notify me about my matches in my email`,
        },
        allUpdates: {
            enabled: true,
            title: ``,
        },
    })

    return (<>
        <div className={`notification-bar block pb-2 pt-3 column pb-5`} data-color={'light-gray'} data-shown={String(showSettings)}>
            <div className={`w-flex`}>
                <div className="row noscroll px-3 pb-3">
                    <div className={`row left noscroll`}>                  
                        <div className={`row left nogap nowrap noscroll osmos-logo`} onClick={() => {
                                props.func();
                                }} style={{height: `40px`, opacity: globalContext.community === undefined ? 0 : 1}}>
                            {!!globalContext.community ?
                            <img src={CommunityLogo[globalContext.community]["black"]} alt=""
                                style={{height: 40}}></img> : <>
                            <img src={OsmosOBlack} alt={`O`} style={{height: sizes[states[logoStep] % 8]}}></img>
                            <img src={OsmosSBlack} alt={`S`} style={{height: sizes[(states[logoStep] + 1) % 8]}}></img>
                            <img src={OsmosMBlack} alt={`M`} style={{height: sizes[(states[logoStep] + 2) % 8]}}></img>
                            <img src={OsmosOBlack} alt={`O`} style={{height: sizes[(states[logoStep] + 3) % 8]}}></img>
                            <img src={OsmosSBlack} alt={`S`} style={{height: sizes[(states[logoStep] + 4) % 8]}}></img>
                            </>}
                        </div>
                        {/* <div className={`block`} data-color={'black'} style={{height: 24, width: 1}}></div>
                        <p className={`semibold`} onClick={() => {requestPermission()}} style={{fontSize: 12}}
                        data-color={'black'}>{`Meet the right crowd.`}<br/>{`Get things done.`}</p>  */}
                    </div>
                    <div className={`row left nogap`} style={{
                        pointerEvents: `none`, opacity: 0,
                    }}>
                        {!props.hideNotifications && context.notifications && false &&
                        <img className={`mr-2`} src={NotificationWhite} alt={`o`}
                            onClick={() => {
                                setShowSettings(false);
                                setShowNotifications(val => !val)
                            }}></img>}
                        {!showSettings ?
                        <img className={`h-3`} src={SettingsBlack} alt={`=`}
                            onClick={() => {
                                setShowNotifications(false);
                                setShowSettings(false);
                                const btn = document.querySelector(`#open-settings-button`);

                                if (btn) {
                                    switch (btn.getAttribute('data-destination')) {
                                        case `invites_new`:     
                                            btn.removeAttribute('data-destination');
                                            setSettingsPopupPage(1);
                                            setSettingsPopup(true);  
                                            break;
                                        case `invites_new_request`:     
                                            setInviteRequestId(btn.getAttribute('data-request-id'));
                                            setSettingsPopupPage(100);
                                            setSettingsPopup(true); 
                                            btn.removeAttribute('data-request-id'); 
                                            btn.removeAttribute('data-destination');
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }} id={`open-settings-button`}></img> :
                        <button className={`btn-close mobile`} onClick={() => {
                                    setShowNotifications(false);
                                    setShowSettings(false);
                                    const btn = document.querySelector(`#open-settings-button`);

                                    if (btn) {
                                        switch (btn.getAttribute('data-destination')) {
                                            case `invites_new`:     
                                                btn.removeAttribute('data-destination');
                                                setSettingsPopupPage(1);
                                                setSettingsPopup(true);  
                                                break;
                                            case `invites_new_request`:     
                                                setInviteRequestId(btn.getAttribute('data-request-id'));
                                                setSettingsPopupPage(100);
                                                setSettingsPopup(true); 
                                                btn.removeAttribute('data-request-id'); 
                                                btn.removeAttribute('data-destination');
                                                break;                                            
                                            case `privacy_policy`:     
                                                setSettingsPopupPage(102);
                                                setSettingsPopup(true);  
                                                btn.removeAttribute('data-destination');
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }}>
                            <div className={`btn-symbol filled w-3 h-3 centered close`} data-color={`black`}>
                                <img src={PlusWhite} alt={`+`}></img>
                            </div>
                        </button>}
                    </div>
                </div>
                <div className={`h-flex notification-list pb-5 px-4`}>
                    {!settingsPage && <>
                        <p data-size={3} className={`my-4`}>{`Settings`}</p>
                        {/* {!props.hideSettings &&
                        <button className={`block mobile b-1 w-flex mb-3 row btn-double btn-flex`} data-color={`gray`}
                                onClick={() => { setSettingsPage(1) }}>
                            <div className={`row left`}>
                                <img src={InvitesWhite} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Invites`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>} */}
                        {!props.hideSettings &&
                        <button className={`block mobile b-1 w-flex mb-1 row btn-double btn-flex`} data-color={`gray`}
                                onClick={() => {
                                    setSettingsPopupPage(101);
                                    setSettingsPopup(true);
                                }}>
                            <div className={`row left`}>
                                <img src={SupportBlack} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Support`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>}
                        <button className={`block mobile b-1 w-flex mb-3 row btn-double btn-flex`} data-color={`gray`}
                                onClick={() => {
                                    setSettingsPopupPage(102);
                                    setSettingsPopup(true);
                                }} type={`button`}>
                            <div className={`row left`}>
                                <img src={ProtectBlack} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Privacy policy`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>
                        {!props.hideSettings &&
                        <button className={`block mobile b-1 w-flex mb-1 row btn-double btn-flex`} data-color={`gray`}
                                onClick={() => {
                                    setSettingsPage(2);
                                }}>
                            <div className={`row left`}>
                                <img src={GearBlack} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Profile settings`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>}
                        {!props.hideSettings &&
                        <button className={`block mobile b-1 w-flex mb-1 row btn-double btn-flex`} data-color={`gray`}
                                onClick={() => {
                                    setSettingsPopupPage(4);
                                    setSettingsPopup(true);
                                }}>
                            <div className={`row left`}>
                                <img src={ExitBlack} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Log out`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>}
                    </>}
                    {!(settingsPage - 1) && <>
                        <button className={`row left my-3 btn-double mobile`} onClick={() => {setSettingsPage(0)}}>
                            <div className={`btn-symbol hover w-3 h-3 centered back mr`} data-color={'black'}>
                                <AngleRight data-color={`black`}/>
                            </div>
                            <p data-size={5}>{`Settings`}</p>
                        </button>
                        <p data-size={3} className={`mb-4 seminarrow`}>{`Create a community by sharing invites with friends`}</p>

                        <button className={`block mobile b-1 w-flex mb-1 row btn-double btn-flex btn-transparent`} data-color={`gray`}
                                onClick={() => { 
                                    setSettingsPopupPage(1);
                                    setSettingsPopup(true);
                                }} disabled={!invites?.available}>
                            <div className={`row left`}>
                                <div className={`block h-3 min-w-4 b-2 centered custom-hover`} data-color={invites?.available ? `green` : 'white-40-opacity'}>
                                    <p data-size={6} className={`narrow not-active`} data-color={`white`}>{invites?.available ?? 0}</p>
                                </div>
                                <p data-size={6} className={`semibold`} data-color={`white`}>{`Available`}</p>
                            </div>
                            <AngleRight data-color={`white-40-opacity`} className={`not-active`}/>
                        </button>
                        <button className={`block mobile b-1 w-flex mb-1 row btn-double btn-flex btn-transparent`} data-color={`gray`}
                                onClick={() => {
                                    setSettingsPopupPage(2); 
                                    setSettingsPopup(true);
                                }} disabled={!invites?.pending?.length}>
                            <div className={`row left`}>
                                <div className={`block h-3 min-w-4 b-2 centered custom-hover`} data-color={invites?.pending?.length ? `green` : 'white-40-opacity'}>
                                    <p data-size={6} className={`narrow not-active`} data-color={`white`}>
                                        {(invites?.pending ?? []).filter(val => !val.hidden).length}
                                    </p>
                                </div>
                                <p data-size={6} className={`semibold`} data-color={`white`}>{`Pending`}</p>
                            </div>
                            <AngleRight data-color={`white-40-opacity`} className={`not-active`}/>
                        </button>
                        <button className={`block mobile b-1 w-flex mb-1 row btn-double btn-flex btn-transparent`} data-color={`gray`}
                                onClick={() => { 
                                    setSettingsPopupPage(3);
                                    setSettingsPopup(true);
                                }} disabled={!invites?.accepted?.length}>
                            <div className={`row left`}>
                                <div className={`block h-3 min-w-4 b-2 centered custom-hover`} data-color={invites?.accepted?.length ? `green` : 'white-40-opacity'}>
                                    <p data-size={6} className={`narrow not-active`} data-color={`white`}>{(invites?.accepted ?? []).length}</p>
                                </div>
                                <p data-size={6} className={`semibold`} data-color={`white`}>{`Accepted`}</p>
                            </div>
                            <AngleRight data-color={`white-40-opacity`} className={`not-active`}/>
                        </button>
                    </>}
                    {!(settingsPage - 2) && !props.hideSettings && <>
                        <button className={`row left my-3 btn-double mobile`} onClick={() => {setSettingsPage(0)}}>
                            <div className={`btn-symbol hover w-3 h-3 centered back mr`} data-color={'black'}>
                                <AngleRight data-color={`black`}/>
                            </div>
                            <p data-size={5}>{`Settings`}</p>
                        </button>
                        <p data-size={3} className={`mb-4 seminarrow`}>{`Profile settings`}</p>
                        <button className={`block b-1 w-flex mb-1 row btn-double mobile btn-flex`} data-color={`gray`}
                                onClick={() => { 
                                    setSettingsPopupPage(107);
                                    setSettingsPopup(true);
                                }}>
                            <div className={`row left`}>
                                <img src={PostBlack} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Email newsletters`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>
                        <button className={`block b-1 w-flex mb-1 row btn-double mobile btn-flex`} data-color={`gray`}
                                onClick={() => {
                                    setSettingsPopupPage(10402);
                                    setSettingsPopup(true);
                                }}>
                            <div className={`row left`}>
                                <img src={TrashBlack} alt={""} className={`w-3`}></img>
                                <p data-size={6} className={`semibold`}>{`Delete account`}</p>
                            </div>
                            <AngleRight data-color={`black-10-opacity`} />
                        </button>
                    </>}
                </div>
            </div>

            <div className={`row w-flex center pb-2`} style={{position: 'sticky', bottom: 0}}>
                <button className={`block btn mobile box-shadow h-5 w-5 b-4 centered`} onClick={() => { 
                    setShowSettings(false);
                    setShowNotifications(false);
                }}>
                    <div className={`btn-symbol w-3 h-3 filled centered close`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>
        </div>

        <div className={`notification-bar block pb-2 pt-3 column pb-5`} data-color={'light-gray'} data-shown={String(showNotifications)}>
            <div className={`w-flex`}>
                <div className="row noscroll px-3 pb-3">
                    <div className={`row left noscroll`}>                  
                        <div className={`row left nogap nowrap noscroll osmos-logo`} onClick={() => {
                                props.func();
                                }} style={{height: `40px`, opacity: globalContext.community === undefined ? 0 : 1}}>
                            {!!globalContext.community ?
                            <img src={CommunityLogo[globalContext.community]["black"]} alt=""
                                style={{height: 40}}></img> : <>
                            <img src={OsmosOBlack} alt={`O`} style={{height: sizes[states[logoStep] % 8]}}></img>
                            <img src={OsmosSBlack} alt={`S`} style={{height: sizes[(states[logoStep] + 1) % 8]}}></img>
                            <img src={OsmosMBlack} alt={`M`} style={{height: sizes[(states[logoStep] + 2) % 8]}}></img>
                            <img src={OsmosOBlack} alt={`O`} style={{height: sizes[(states[logoStep] + 3) % 8]}}></img>
                            <img src={OsmosSBlack} alt={`S`} style={{height: sizes[(states[logoStep] + 4) % 8]}}></img>
                            </>}
                        </div>
                        {/* <div className={`block`} data-color={'black'} style={{height: 24, width: 1}}></div>
                        <p className={`semibold`} onClick={() => {requestPermission()}} style={{fontSize: 12}}
                        data-color={'black'}>{`Meet the right crowd.`}<br/>{`Get things done.`}</p>  */}
                    </div>
                    <div className={`row left nogap`} style={{
                        pointerEvents: `none`, opacity: 0,
                    }}>
                        {!props.hideNotifications && context.notifications && false &&
                        <img className={`mr-2`} src={NotificationWhite} alt={`o`}
                            onClick={() => {
                                setShowSettings(false);
                                setShowNotifications(val => !val)
                            }}></img>}
                        {!showSettings ?
                        <img className={`h-3`} src={SettingsBlack} alt={`=`}
                            onClick={() => {
                                setShowNotifications(false);
                                setShowSettings(false);
                                const btn = document.querySelector(`#open-notifications-button`);

                                if (btn) {
                                    switch (btn.getAttribute('data-destination')) {
                                        default:
                                            break;
                                    }
                                }
                            }} id={`open-settings-button`}></img> :
                        <button className={`btn-close mobile`} onClick={() => {
                                    setShowNotifications(false);
                                    setShowSettings(false);
                                    const btn = document.querySelector(`#open-notifications-button`);

                                    if (btn) {
                                        switch (btn.getAttribute('data-destination')) {
                                            default:
                                                break;
                                        }
                                    }
                                }}>
                            <div className={`btn-symbol filled w-3 h-3 centered close`} data-color={`black`}>
                                <img src={PlusWhite} alt={`+`}></img>
                            </div>
                        </button>}
                    </div>
                </div>
                <div className={`h-flex notification-list pb-5 px-3`}>
                    {!!context.notifications?.length ?
                    <div className={`w-flex b-3 p-3 block`}>
                    {(context.notifications ?? []).map((elem, index) => {return (
                        <div className={`w-flex row left top nowrap ${!!index ? `mt-2` : ``}`} key={index}>
                            <div className={`h-5 w-3 centered noshrink neg-ml-3 neg-mr-1`} hidden={elem.is_read}>
                                <div className={`block w-1 h-1 b`} data-color={`coral`}></div>
                            </div>  
                            <NewAvatar size={5} name={`${elem.fname} ${elem.lname}`} type={elem.avatar?.style}
                                    avatar={elem.avatar?.link}/>
                            <div>                            
                                <p data-size={6} data-lines={3} className={`mb-1`}>
                                    <span className={`semibold`}>
                                        {elem?.type < 8 ? `${elem?.fname} ${elem.lname} ` : 
                                        elem?.type === 8 ? "" : "New interesting request "}
                                    </span>
                                    {elem.type === 0 ? `is ready to help you: ${elem.text}` :
                                    elem.type === 1 ? `is your new match!` :
                                    elem.type === 2 ? `sent you a new message: ${elem.text}` :
                                    elem.type === 3 ? `started following you!` :
                                    elem.type === 4 ? `posted a new request: ${elem.text}` : 
                                    elem.type === 5 ? `started following ${elem.text} new people. View ${elem.fname}'s following to find new connections.` :
                                    elem.type === 6 ? <>
                                        {`suggested you for `}
                                        <span className={`semibold`}>{`${elem.target?.name}'s `}</span>
                                        {`request: ${elem.text}`}
                                    </> : 
                                    elem.type === 7 ? <>
                                        {`suggested `}
                                        <span className={`semibold`}>{elem.target?.name}</span>
                                        {` for your request: ${elem.text}`}
                                    </> : 
                                    elem.type === 8 ? <>
                                        {`Seems like `} 
                                        <span className={`semibold`}>{`${elem?.fname} ${elem.lname}`}</span>
                                        {` and 5 more people `}
                                        <span className={`semibold`}>{`can help`}</span>
                                        {` you with your request`}
                                    </> : <>
                                        {`for you from `}
                                        <span className={`semibold`}>{`${elem?.fname} ${elem.lname}`}</span>
                                    </>}
                                </p>
                                <a className={`block btn-rectangle-24 mobile row`} 
                                data-color={(elem.type === 1 || elem.type === 2) ? `black` : `light-gray`} 
                                href={elem.type === 0 ? `/profile/chat?offer_id=0` :
                                        elem.type === 1 ? `/messenger/${elem.target_id}` :
                                        elem.type === 2 ? (elem?.chat_offer ? `profile/chat?offer_id=0` : `/messenger/${elem.target_id}`) :
                                        elem.type === 3 ? `/profile/${elem.target_id}` :
                                        elem.type === 4 ? `/request/${elem.target_id}` :
                                        elem.type === 5 ? `/profile/${elem.target_id}?target=following` : 
                                        elem.type === 6 ? `/request/${elem.target_id}` : 
                                        elem.type === 7 ? `/profile/${elem.target_id}` : 
                                        elem.type === 8 ? `/profile/${elem?.target_id}` : `/request/${elem?.target_id}`} 
                                style={{width: `max-content`}}>
                                    <p>{elem.type === 0 ? `Go to Responses to me` :
                                        elem.type === 1 ? `Go to chat` :
                                        elem.type === 2 ? `Go to chat` :
                                        elem.type === 3 ? `Go to profile` :
                                        elem.type === 4 ? `Go to request` :
                                        elem.type === 5 ? `Expand your network` :
                                        elem.type === 6 ? `Go to request` :
                                        elem.type === 7 ? `Go to profile` : 
                                        elem.type === 8 ? `Check recommendation` : `Check request`}</p>
                                </a>
                            </div>
                        </div>
                    )})}
                    </div> :
                    <MessageCardMobile type={`notifications`} className={`mb-3`}/>}
                </div>
            </div>

            <div className={`row w-flex center pb-2`} style={{position: 'sticky', bottom: 0}}>
                <button className={`box-shadow btn mobile block h-5 w-5 b-4 centered`} onClick={() => { 
                    setShowSettings(false);
                    setShowNotifications(false);
                }}>
                    <div className={`btn-symbol w-3 h-3 filled centered close`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>
        </div>

        <div className={`notification-bar block pb-2 pt-3 column pb-5`} data-color={'light-gray'} data-shown={String(showSearch)}>
            <div className={`w-flex`}>
                <div className="row noscroll px-3 pb-3">
                    <div className={`row left noscroll`}>                  
                        <div className={`row left nogap nowrap noscroll osmos-logo`} onClick={() => {
                                props.func();
                                }} style={{height: `40px`, opacity: globalContext.community === undefined ? 0 : 1}}>
                            {!!globalContext.community ?
                            <img src={CommunityLogo[globalContext.community]["black"]} alt=""
                                style={{height: 40}}></img> : <>
                            <img src={OsmosOBlack} alt={`O`} style={{height: sizes[states[logoStep] % 8]}}></img>
                            <img src={OsmosSBlack} alt={`S`} style={{height: sizes[(states[logoStep] + 1) % 8]}}></img>
                            <img src={OsmosMBlack} alt={`M`} style={{height: sizes[(states[logoStep] + 2) % 8]}}></img>
                            <img src={OsmosOBlack} alt={`O`} style={{height: sizes[(states[logoStep] + 3) % 8]}}></img>
                            <img src={OsmosSBlack} alt={`S`} style={{height: sizes[(states[logoStep] + 4) % 8]}}></img>
                            </>}
                        </div>
                        {/* <div className={`block`} data-color={'black'} style={{height: 24, width: 1}}></div>
                        <p className={`semibold`} onClick={() => {requestPermission()}} style={{fontSize: 12}}
                        data-color={'black'}>{`Meet the right crowd.`}<br/>{`Get things done.`}</p>  */}
                    </div>
                </div>

                <div className={`px-3`}>
                    <form className={`block p-2 b-3 row nowrap left w-flex`}
                        onSubmit={e => {
                            e.preventDefault();
                            try {
                                context.setGlobalSearch(e.target.search.value ?? "");
                                context.setPage(1);
                                context.setTab(1);
                            } catch (err) {
                                navigate(`/profile/explore?search=${encodeURIComponent(e.target.search.value)}`);
                            }
                            setShowSearch(false);
                        }} style={{position: `relative`}}>
                        <input ref={searchInput} data-size={6} style={{
                            height: 24, backgroundColor: `transparent`,   
                        }} placeholder={`Search`} className={`w-flex pl`} name={`search`}
                        onFocus={() => { setOpenSearch(true) }} onBlur={e => {
                            if (e.relatedTarget?.id !== `search-option` && !e.target.value?.length) {
                                clearTimeout(timer);
                                setOpenSearch(false);
                            }
                        }} onChange={e => {
                            clearTimeout(timer);
                            if (!!e.target.value?.length) {
                                setTimer(setTimeout(() => {
                                    SearchRequest(e.target?.value ?? "", 5, setSearchData);
                                }, 500))
                            } else {
                                setSearchData(undefined);
                            }
                        }}></input>
                        <img src={LensBlack} alt={``} className={`w-3`}></img>

                        <div className={`block py-2 b-3 max-h-8 y-auto`} 
                            hidden={!(openSearch && (searchData?.users?.length > 0 ||  JSON.parse(localStorage.getItem(`search_history`) ?? `[]`)?.length > 0))}
                            style={{position: `absolute`, left: 0, right: 0, top: 56, zIndex: 2}}>
                            {searchData?.users?.length > 0 ? (searchData?.users ?? []).map((elem, index) => {return (
                                <a className={`btn mobile row left nowrap w-flex px-2 py`} key={index} type={`button`}
                                href={`/profile/${elem.unique_id ?? elem.id}`} id={`search-option`} tabIndex={index}>
                                    <p data-size={6}>{elem.name}</p>
                                </a>
                            )}) : JSON.parse(localStorage.getItem(`search_history`) ?? `[]`).map((elem, index) => {return (
                                <button className={`btn mobile row left nowrap w-flex px-2 py`} key={index} type={`button`}
                                        onClick={() => {
                                            searchInput.current.value = elem;
                                            try {
                                                context.setGlobalSearch(elem);
                                                context.setPage(1, 1);
                                                context.setTab(1, 1);
                                            } catch (err) {
                                                navigate(`/profile/explore?search=${encodeURIComponent(elem)}`);
                                            }
                                            setShowSearch(false);
                                        }} id={`search-option`} tabIndex={index}>
                                    <img src={HistoryGray} alt={``} className={`w-3`}></img>
                                    <p data-size={6}>{elem}</p>
                                </button>
                            )})}
                        </div>
                    </form>
                </div>
            </div>

            <div className={`row w-flex center pb-2`} style={{position: 'sticky', bottom: 0}}>
                <button className={`box-shadow btn mobile block h-5 w-5 b-4 centered`} onClick={() => { 
                    setShowSettings(false);
                    setShowNotifications(false);
                    setShowSearch(false);
                }}>
                    <div className={`btn-symbol w-3 h-3 filled centered close`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>
        </div>
    
        <div className={`block ${props.toTop ? "block-to-top" : "block-static-top"} p-3 pb-2 ${props.className}`} data-color={showSettings ? 'light-gray' : (props.color ?? 'light-gray')}>
            <div className="row noscroll">
                <div className={`row left noscroll`}> 
                    <div className={`row left nogap nowrap noscroll osmos-logo`} onClick={() => {
                            props.func();
                            }} style={{height: `40px`, opacity: globalContext.community === undefined ? 0 : 1}}>
                        {!!globalContext.community ?
                        <img src={CommunityLogo[globalContext.community][ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1]]} alt=""
                            style={{height: 40}}></img> : <>
                        <img src={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1] === 'white' ? OsmosO : OsmosOBlack} 
                             alt={`O`} style={{height: sizes[states[logoStep] % 8]}}></img>
                        <img src={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1] === 'white' ? OsmosS : OsmosSBlack} 
                             alt={`S`} style={{height: sizes[(states[logoStep] + 1) % 8]}}></img>
                        <img src={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1] === 'white' ? OsmosM : OsmosMBlack} 
                             alt={`M`} style={{height: sizes[(states[logoStep] + 2) % 8]}}></img>
                        <img src={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1] === 'white' ? OsmosO : OsmosOBlack} 
                             alt={`O`} style={{height: sizes[(states[logoStep] + 3) % 8]}}></img>
                        <img src={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1] === 'white' ? OsmosS : OsmosSBlack} 
                             alt={`S`} style={{height: sizes[(states[logoStep] + 4) % 8]}}></img>
                        </>}
                    </div>
                    
                    {!!CommunityLogo[props.community] &&
                    <img src={ScreenColorsDict[props?.color ?? 'light-gray'][1] === 'white' ? 
                        CommunityLogo[props.community]?.topIcon ?? CommunityLogo[props.community]?.white :
                        CommunityLogo[props.community]?.topIconBlack ?? CommunityLogo[props.community]?.black} 
                        alt="" style={{height: 40, marginLeft: 18}}></img>}
                    {/* {!cookie[`user_id_cookie`] && props.hideLogIn && <>
                    <div className={`block`} data-color={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1]} style={{height: 24, width: 1}}></div>
                    <p className={`semibold`} style={{fontSize: 12}}
                       data-color={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1]}>{`Meet the right crowd.`}<br/>{`Get things done.`}</p></>} */}
                </div>
                {!cookie[`user_id_cookie`] ? 
                <div className={`row nowrap left`}>
                    {props.hideLogIn ?
                    <a className={`block btn-circled-32 mobile row`} data-color={`black`}
                        href={`/blog`} hidden={true}>
                        <p>{`Blog`}</p>
                    </a> : 
                    <a className={`block btn-circled-40 mobile p-left p-right row`}
                        href={`/blog`} hidden={!!globalContext.community}>
                        <img src={PageListBlack} alt={``} className={`w-3`}></img>
                    </a>}
                    <button className={`block btn-circled-40 mobile neg-my-1`} data-color={`black`} 
                            onClick={() => { setSignInPopup(true) }} hidden={props.hideLogIn}>
                        <p>{`Sign in`}</p>
                    </button> 
                </div> :
                <div className={`row left nogap nowrap`}>
                    {(!showSettings && !showNotifications) ? <>
                        <img className={`h-3`} src={ScreenColorsDict[showSettings ? 'light-gray' : (props.color ?? 'light-gray')][1] === 'white' ? SettingsWhite : SettingsBlack} alt={`=`}
                            onClick={() => {
                                setShowNotifications(false);
                                setShowSettings(val => !val)
                            }} id={`open-settings-button-main`} style={{
                                opacity: 0, pointerEvents: `none`, width: 0, height: 0,
                            }}></img>  
                        
                        <div className={`row nowrap left`}>
                            {/* <button className={`block btn-circled-40 mobile p-left p-right row`} type={`button`} onClick={() => {
                                setShowSearch(val => !val);
                                setShowNotifications(false);
                            }} hidden={!!searchParams.get(`new_user`) || !cookie[`user_id_cookie`]}
                               disabled={!context?.isSubscriptionActive}>
                                {showSearch ? 
                                <div className={`btn-symbol w-3 h-3 filled centered close`}>
                                    <img src={PlusWhite} alt={`+`}></img>
                                </div> :
                                <img src={!context?.isSubscriptionActive ? LensWhite : LensBlack} alt={``} className={`w-3`}></img>}
                            </button> */}
                            <a className={`block btn-circled-40 mobile p-left p-right row`}
                            href={`/blog`} hidden={!!globalContext.community}>
                                <img src={PageListBlack} alt={``} className={`w-3`}></img>
                            </a>                        
                            {!props.hideNotifications && !!context.notifications &&
                            <ShowNotificationsPopupButtonMobile disabled={context.notifications?.length === 0 || !context?.isSubscriptionActive} 
                                                                number={(context.notifications ?? []).filter((val) => !val.is_read && !val.isRead)?.length}
                                                                onClick={() => {
                                                                    setShowSettings(false);
                                                                    setShowSearch(false);
                                                                    setShowNotifications(val => !val);
                                                                }}/>}
                        </div>
                    </> :
                    <button className={`btn-close mobile`} onClick={() => {
                            setShowNotifications(false);
                            setShowSettings(false)
                        }}>
                        <div className={`btn-symbol filled w-3 h-3 centered close`} data-color={`black`}>
                            <img src={PlusWhite} alt={`+`}></img>
                        </div>
                    </button>}
                </div>}
            </div>
        </div>        
        
        {(showSettings || true) &&
        <PopUp customZIndex={202} content={<>                       
                    <div className={`row block top noscroll nowrap nogap neg-mx-4 pl-4 pr-4 p-3 neg-mt-5 pt-5`} id={`settings-header-popup-content`}>
                        {(!(settingsPopupPage - 10401) || !(settingsPopupPage - 10403)) ?
                        <NewAvatar size={6} type={props.profile?.avatar?.style ?? 0} name={props.profile?.name ?? 'John Doe'}
                                   avatar={props.profile?.avatar?.link}/> : <>
                        <p data-size={4} className={'semibold mr-3'} data-color={'black'}>
                            {(!(settingsPopupPage - 1) || !(settingsPopupPage - 100)) && `You have ${invites?.available ?? 0} more invites to share with your friends`}
                            {!(settingsPopupPage - 2) && `Pending requests`}
                            {!(settingsPopupPage - 3) && `Accepted requests`}
                            {!(settingsPopupPage - 4) && `By logging out, you will be signed out of your current session`}
                            {!(settingsPopupPage - 101) && `If you have any questions — email us!`}
                            {!(settingsPopupPage - 102) && `Privacy policy`}
                            {!(settingsPopupPage - 103) && `User agreement`}
                            {!(settingsPopupPage - 104) && `Delete your account`}
                            {(!(settingsPopupPage - 106) || !(settingsPopupPage - 10600)) && `Let your friend scan this QR code`}
                            {!(settingsPopupPage - 107) && `Email preferences`}
                            {!(settingsPopupPage - 10400) && `Marketing emails`}
                            {!(settingsPopupPage - 10402) && `${props.profile?.fname ?? 'Friend'}, we are sorry to see you go`}
                        </p>
                        </>}
                        {/* <button className={`mobile btn-symbol w-3 h-3 filled close centered`} onClick={() => {
                            setSettingsClosePopup(true);
                        }}>
                            <img src={PlusWhite} alt={`+`}></img>
                        </button> */}
                    </div>
                    
                        {!(settingsPopupPage - 1) && <>
                        <button className={`block mobile p-2 b-1 row center w-flex mb-1 btn-double btn-flex`} data-color={'blue'} 
                                onClick={() => {
                                    InviteTokenUse(setNewInvite);
                                }}>
                            <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Send link`}</p>
                        </button> 

                        <button className={`block mobile p-2 b-1 row center w-flex btn-double btn-flex`} data-color={'black'} 
                                onClick={() => {
                                    InviteTokenUse(setNewQRInvite, '', 1, {
                                        200: () => {
                                            setSettingsPopupPage(106);
                                        }
                                    });
                                }}>
                            <p data-size={6} className={`semibold`} data-color={`white`}>{`Create QR code`}</p>
                        </button>  

                        <div className={`block w-flex b-3 p-3 row top nogap nowrap mt-3`} data-color={`light-gray`} id={`copied-to-clipboard-label`} 
                             hidden={!copiedToClipboardLabel}>
                            <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                                <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                            </div>
                            <p data-size={6} className={`semibold w-flex pl-4`} data-color={`black`}>{`Link successfully copied to clipboard`}</p>
                        </div>         
                        </>}

                        {!(settingsPopupPage - 100) && <>
                        <button className={`block mobile b-3 row center w-flex mb-1 btn-color`} data-color={'blue'} 
                                onClick={() => {
                                    InviteTokenUse(setNewInviteRequest, inviteRequestId);
                                }}>
                            <p>{`Send link`}</p>
                        </button> 

                        <button className={`block mobile b-3 row center w-flex btn-color`} data-color={'black'} 
                                onClick={() => {
                                    InviteTokenUse(setNewQRInviteRequest, inviteRequestId, 1, {
                                        200: () => {
                                            setSettingsPopupPage(10600);
                                        }
                                    });
                                }}>
                            <p>{`Create QR code`}</p>
                        </button>  

                        <div className={`block w-flex b-3 p-3 row top nogap nowrap mt-3`} data-color={`light-gray`} id={`copied-to-clipboard-label`} 
                             hidden={!copiedToClipboardLabel}>
                            <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                                <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                            </div>
                            <p data-size={5} className={`semibold w-flex pl-4`} data-color={`black`}>{`Link successfully copied to clipboard`}</p>
                        </div>         
                        </>}

                        {!(settingsPopupPage - 2) && 
                        <div className={`column auto h-9 neg-mb-4`} onScroll={e => {
                            const header = document.querySelector(`#settings-header-popup-content`)
                            if (header) {
                                header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : null
                            }
                        }}>
                            {(invites?.pending ?? []).map((elem, index) => {return (
                            <div className={`block b-3 p-2 w-flex mb-2`} data-color={`light-gray`} key={index}
                                style={{
                                    transition: `margin 0.3s ease-in-out`,
                                }} id={`pending-link-block-${elem?.token}`} hidden={elem.hidden}>
                                <p data-size={6} className={`mb-2`} data-color={`gray`}>{DateTimeFromString(elem?.time ?? Date.now() / 1000)}</p>
                                <button className={`block row left nogap nowrap b-1 w-flex mb-2 mobile btn-double btn-flex`} onClick={() => {
                                    if (window.isSecureContext) {                  
                                        navigator.clipboard.writeText(`${window.location.origin}?code=${elem?.token}`)
                                        .then(() => {;
                                            if (document.querySelector(`#copied-to-clipboard-label`)) {
                                                // document.querySelector(`#copied-to-clipboard-label`).hidden = false;
                                            }
                                        })
                                    }
                                }}>
                                    <p data-size={6} className={`w-flex pr-4 ellipsis`} data-color={`black`}>{`${window.location.origin}?code=${elem?.token}`}</p>
                                    <img className={`w-3 neg-ml-3 hover-icon`} src={LinkBlack} alt={""}></img>
                                </button>
                                <button className={`block mobile b-4 p-1 pl-2 pr-2 centered btn-color`} data-color={`black`}
                                        onClick={() => {
                                            InviteTokenBurn(elem?.token, () => {
                                                const pending_invite_elem = document.querySelector(`#pending-link-block-${elem?.token}`)
                                                pending_invite_elem.style.marginLeft = `800px`;
                                                setTimeout(() => {
                                                    pending_invite_elem.style.marginTop = `-${14 + pending_invite_elem.scrollHeight}px`;
                                                    setTimeout(() => {
                                                        setInvites(val => SetDict(val, [`pending`, val.pending.findIndex(val => val.token === elem?.token), 'hidden'], true));
                                                    }, 300)
                                                }, 300) 
                                            })
                                        }}>
                                    <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Revoke the link`}</p>
                                </button>
                            </div>)})}
                        </div>}

                        {!(settingsPopupPage - 3) && 
                        <div className={`column auto h-9`} onScroll={e => {
                            const header = document.querySelector(`#settings-header-popup-content`)
                            if (header) {
                                header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : null
                            }
                        }}>
                        {(invites?.accepted ?? []).map((elem, index) => {return (
                            <div className={`block b-2 p-2 w-flex mb-2`} data-color={`light-gray`} key={index}>
                                <div className={`row nogap top mb-2`}>
                                    <div className={`w-flex pr-6`}>
                                        <p data-size={6} className={`mb-2`} data-color={`gray`}>{DateTimeFromString(elem?.time ?? Date.now() / 1000)}</p>
                                        <p data-size={5} className={``} data-color={`black`}>
                                            <span data-color={`blue`}>{elem?.name ?? `User`}</span>
                                            {` joined Osmos.`}
                                        </p>
                                    </div>
                                    <NewAvatar name={elem?.name ?? `. .`} type={elem?.avatar?.style ?? 0} 
                                            avatar={elem?.avatar?.link} size={5} className={`neg-ml-5`}/>
                                </div>
                                <div className={`row left`}>
                                    <button className={`block mobile b-4 p-1 pl-2 pr-2 centered btn-color`} data-color={`black`}
                                            onClick={() => {
                                                Subscribe(cookie['user_id_cookie'], elem?.id);
                                            }} disabled={elem?.followed} id={`follow-user-btn`}>
                                        <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Follow`}</p>
                                    </button>
                                    <button className={`block mobile b-4 p-1 pl-2 pr-2 centered btn-color`} data-color={`tp-black`}
                                            onClick={() => {}}>
                                        <p data-size={6} className={`semibold hover`} data-color={`black`}>{`Later`}</p>
                                    </button>
                                </div>
                            </div>
                        )})}
                        </div>}

                        {!(settingsPopupPage - 4) && <>
                            <button className={`block mobile p-2 b-1 row center w-flex mb-1 btn-double btn-flex`} data-color={'black'} 
                                    onClick={() => {
                                        setSettingsClosePopup(true);
                                    }}>
                                <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Cancel`}</p>
                            </button>

                            <button className={`block mobile p-2 b-1 row center w-flex btn-double btn-flex`} data-color={'light-gray'} 
                                    onClick={() => { LogOut() }}>
                                <p data-size={6} className={`semibold `} data-color={`black`}>{`Log out`}</p>
                            </button> 
                        </>}

                        {!(settingsPopupPage - 101) && <>
                            <button className={`block p-2 b-1 row w-flex mb-2 mobile btn-double btn-flex`} data-color={'light-gray'} onClick={() => {
                                        if (window.isSecureContext) {
                                            navigator.clipboard.writeText(`support@osmos.social`).then(() => {
                                                // document.querySelector(`#copied-to-clipboard-label`).hidden = false;
                                                setCopiedToClipboardLabel(true);
                                                setTimeout(() => {
                                                    // document.querySelector(`#copied-to-clipboard-label`).hidden = true;
                                                    setCopiedToClipboardLabel(false);
                                                }, 2000)
                                            });
                                        }
                                    }} >
                                <p data-size={6} data-color={`black`}>{`support@osmos.social`}</p>
                                <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
                            </button>

                            <div className={`block w-flex b-3 p-3 row top nogap nowrap mt-3`} data-color={`light-gray`} id={`copied-to-clipboard-label`} 
                                 hidden={!copiedToClipboardLabel}>
                                <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                                    <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                                </div>
                                <p data-size={5} className={`semibold w-flex pl-4`} data-color={`black`}>{`Link successfully copied to clipboard`}</p>
                            </div>
                        </>}

                        {!(settingsPopupPage - 102) && <>
                            <PrivacyPolicy className={`neg-mb-4`} onScroll={e => {
                                const header = document.querySelector(`#settings-header-popup-content`)
                                if (header) {
                                    header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : null
                                }
                            }}/>
                        </>}

                        {!(settingsPopupPage - 103) && <>
                        </>}

                        {!(settingsPopupPage - 104) && <>
                            <p data-size={5} className={`w-flex mb-3`} data-color={`black`}>
                                {`When you delete your account, your matches will be permanently removed. You can also choose to take a break and unsubscribe from all email newsletters instead.`}
                            </p> 

                            <button className={`block mobile p-2 b-1 row center w-flex mb-1 btn-double btn-flex`} data-color={'black'} 
                                    onClick={() => {
                                        setSettingsPopupPage(10401);
                                    }}>
                                <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Unsubscribe`}</p>
                            </button>

                            <button className={`block mobile p-2 b-1 row center w-flex btn-double btn-flex`} data-color={'light-gray'} 
                                    onClick={() => {
                                        setSettingsPopupPage(10402);
                                    }}>
                                <p data-size={6} className={`semibold `} data-color={`black`}>{`Delete account`}</p>
                            </button>  
                        </>}

                        {!(settingsPopupPage - 106) && <div className={`row center w-flex`}>
                        <div className={`block mobile p-2 b-2 mb-2 w-8 h-8 centered`} data-color={'light-gray'}>
                            <div className={`block b-1 p-4`}>
                                <QRCode className={`w-flex h-flex`} value={`${window.location.origin}?code=${newQRInvite?.token}`}/>
                            </div>
                        </div>                        
                        </div>}                        

                        {!(settingsPopupPage - 10600) && <div className={`row center w-flex`}>
                        <div className={`block mobile p-2 b-2 mb-2 w-8 h-8 centered`} data-color={'light-gray'}>
                            <div className={`block b-1 p-4`}>
                                <QRCode className={`w-flex h-flex`} value={`${window.location.origin}/invite/${newQRInviteRequest?.token}`}/>
                            </div>
                        </div>                        
                        </div>}

                        {!(settingsPopupPage - 107) && <>
                            <p data-size={5} className={`semibold mb-3`} data-color={`black`}>
                                {`Marketing emails and announcements`}
                            </p> 

                            {[`calendarUpdates`, `projectUpdates`, `platformUpdates`].map((elem, index) => {return (
                                <button className={`w-flex mobile btn-checkbox row left top nowrap mb-2`} data-selected={emailPrefs[elem].enabled} key={index}
                                        onClick={() => {
                                            setEmailPrefs(val => SetDict(val, [elem, `enabled`], !val[elem].enabled));
                                        }}>
                                    <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={emailPrefs[elem].enabled}>
                                        <img src={OkWhite} alt={`v`}></img>
                                    </div>
                                    <p data-size={5}>{emailPrefs[elem].title}</p>
                                </button>
                            )})}

                            <button className={`block mobile p-2 b-1 row center w-flex mb-1 mt-3 btn-flex`} data-color={'black'} 
                                    onClick={() => {
                                        EmailPrefsUpdate(props.profile?.id, {
                                            "emails_matches_calendar_reminders": emailPrefs[`calendarUpdates`].enabled,
                                            "emails_project_news": emailPrefs[`projectUpdates`].enabled,
                                            "emails_platform_updates": emailPrefs[`platformUpdates`].enabled,
                                        }, () => {
                                            setSettingsClosePopup(true);
                                            props.setProfile(val => SetDict(val, [`emails_matches_calendar_reminders`], emailPrefs[`calendarUpdates`].enabled));
                                            props.setProfile(val => SetDict(val, [`emails_platform_updates`], emailPrefs[`platformUpdates`].enabled));
                                            props.setProfile(val => SetDict(val, [`emails_project_news`], emailPrefs[`projectUpdates`].enabled));
                                        })
                                    }}>
                                <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Save`}</p>
                            </button>

                            <button className={`py-2 mobile b-1 row center w-flex btn-flex`}
                                    onClick={() => {
                                        setSettingsPopupPage(10400);
                                    }}>
                                <p data-size={6} className={`semibold center`} data-color={`black`}>{`Unsubscribe from all marketing emails`}</p>
                            </button>  
                        </>}

                        {!(settingsPopupPage - 10400) && <>
                            <p data-size={5} className={`mb-3`} data-color={`black`}>
                                {`Wanna take a break and unsubscribe from all marketing emails?`}
                            </p> 

                            <button className={`block mobile p-2 b-1 row center w-flex mb-1 mt-3 btn-flex`} data-color={'black'} 
                                    onClick={() => {
                                        setSettingsPopupPage(107);
                                    }}>
                                <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Go back`}</p>
                            </button>

                            <button className={`block mobile p-2 b-1 row center w-flex btn-flex`} data-color={'light-gray'} 
                                    onClick={() => {
                                        EmailPrefsUpdate(props.profile?.id, {
                                            "emails_matches_calendar_reminders": false,
                                            "emails_project_news": false,
                                            "emails_platform_updates": false,
                                        }, () => {
                                            setSettingsPopupPage(10401);
                                            props.setProfile(val => SetDict(val, [`emails_matches_calendar_reminders`], false));
                                            props.setProfile(val => SetDict(val, [`emails_platform_updates`], false));
                                            props.setProfile(val => SetDict(val, [`emails_project_news`], false));
                                        })
                                    }}>
                                <p data-size={6} className={`semibold `} data-color={`black`}>{`Unsubscribe`}</p>
                            </button> 
                        </>}

                        {!(settingsPopupPage - 10401) && <>
                        <p data-size={4} className={`semibold w-10 mb-3`} data-color={`black`}>
                            {`Thank you for staying with us, ${props.profile?.fname ?? 'friend'}, now you are unsubscribed from all newsletters`}
                        </p>

                        <button className={`block mobile p-2 b-1 row center w-flex btn-double btn-flex`} data-color={'blue'} 
                                onClick={() => {
                                    setSettingsClosePopup(true);
                                }}>
                            <p data-size={6} className={`semibold hover`} data-color={`white`}>{`Thanks`}</p>
                        </button> 
                        </>}

                        {!(settingsPopupPage - 10402) && <>
                        <div className={`column left w-flex auto h-8`} id={`select-list`} onScroll={e => {
                                const header = document.querySelector(`#settings-header-popup-content`)
                                if (header) {
                                    header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : null
                                }
                            }}>
                            <div className={`w-flex`}>
                                {select.map((elem, index) => {return (                
                                    <button className={`block mobile mobile row top left nowrap noscroll mb-2 w-flex btn-checkbox`} data-selected={elem.enabled}
                                            key={index} onClick={() => {
                                                if (!(index - 5) && !select[5].enabled) {
                                                    setTimeout(() => {
                                                        document.querySelector(`#select-list`).scrollTo(0, document.querySelector(`#select-list`).scrollHeight);
                                                    }, 200)
                                                }
                                                setSelect(select => SetDict(select, [index, 'enabled'], !select[index].enabled))
                                            }}>
                                        <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={elem.enabled}>
                                            <img src={OkWhite} alt={`v`}></img>
                                        </div>
                                        <p data-size={6} className={`light`} style={{marginTop: 2}}>{elem.title}</p>
                                    </button>
                                )})}
                            </div>

                            {select[5].enabled && <>
                                <p className={`text-5 semibold mt-3`}>{`Other`}</p>
                                <textarea className={`w-flex h-7 noshrink mt-2 b-1 p-2`} placeholder={`Type a description`} data-size={6}
                                        ref={otherDescription} onChange={e => {setDeleteReason(e.target.value)}}></textarea>
                            </>}
                        </div>

                        <button className={`block mobile p-2 b-1 mb-1 mt-3 row center w-flex btn-double btn-flex`} data-color={'black'} 
                                onClick={() => {
                                    setSettingsClosePopup(true);
                                }}>
                            <p className={`text-6 semibold hover`} data-color={`white`}>{`Go back`}</p>
                        </button> 

                        <button className={`block mobile p-2 b-1 row center w-flex btn-double btn-flex`} data-color={'light-gray'} 
                                onClick={() => {
                                    if (select.filter(val => val.enabled).length && 
                                        (!select[5].enabled || !!deleteReason)) {
                                        setSettingsPopupPage(10403)
                                    }
                                }} disabled={!select.filter(val => val.enabled).length}>
                            <p className={`text-6 semibold hover`} data-color={`black`}>{`Delete account`}</p>
                        </button>
                        </>}

                        {!(settingsPopupPage - 10403) && <>
                        <p className={`text-4 semibold w-flex mb-3`} data-color={`black`}>
                            {`Thank’s for your feedback, ${props.profile?.fname ?? 'friend'}, we are constantly improving Osmos`}
                        </p>            

                        <button className={`block mobile p-2 b-1 mb-1 row center w-flex btn-double btn-flex`} data-color={'black'} 
                                onClick={() => {
                                    setSettingsClosePopup(true);
                                }}>
                            <p className={`text-6 semibold hover`} data-color={`white`}>{`Give us one more chance`}</p>
                        </button> 

                        <button className={`block mobile p-2 b-1 row center w-flex btn-double btn-flex`} data-color={'light-gray'} 
                                onClick={() => {
                                    ProfileDelete(props.profile?.links?.email, () => { LogOut() });
                                }}>
                            <p className={`text-6 semibold hover`} data-color={`black`}>{`Delete account`}</p>
                        </button> 
                        </>}
                    </>} show={settingsPopup} setShow={setSettingsPopup} close={settingsClosePopup}/>}
        

        {signInPopup  && !props.hideLogIn &&
        <PopupSignInMobile show={signInPopup} setShow={setSignInPopup}/>}
    </>)
}

const TopBarDesktop = (props) => {
    const [ showSettingsPopup, setShowSettingsPopup ] = useState(false); 

    return (<>
        <div className={`block ${props.toTop ? "block-to-top" : "block-static-top"} px-5 py-2 ${props.className}`} data-color={props.color ?? 'light-gray'}>
            <div className="row noscroll nowrap">    
                <div onClick={() => {
                    if (typeof props.func === `function`) {
                        props.func();
                    }
                }} className="row left nowrap gap-4 clickable">            
                    {!!CommunityLogo[props.community] ?
                    <img src={ScreenColorsDict[props?.color ?? 'light-gray'][1] === 'white' ? 
                        CommunityLogo[props.community]?.topIcon ?? CommunityLogo[props.community]?.white :
                        CommunityLogo[props.community]?.topIconBlack ?? CommunityLogo[props.community]?.black} 
                        alt="" style={{height: 40}}></img> :                        
                    <AnimatedLogo color={ScreenColorsDict[props?.color ?? 'light-gray'][1]} onClick={props.func}/>}
                </div>
                
                <div className={`row left nogap noscroll nowrap`}>
                    <button className={`btn-close ${ScreenColorsDict[props?.color ?? 'light-gray'][1] === 'white' ? 'white' : ''} block b-2 w-5 h-5 centered`} data-color={`white-10-opacity`}
                            onClick={() => {
                                setShowSettingsPopup(val => !val);
                            }} id={`open-settings-button`} style={{
                                opacity: 0,
                                pointerEvents: `none`,
                            }}>
                        <img className={`w-3`} src={ScreenColorsDict[props?.color ?? 'light-gray'][1] === 'white' ? SettingsWhite : SettingsBlack} alt={`=`}></img>
                    </button>
                    <a className={`block btn-circled-32 desktop row`} href={`/blog`} data-color={`black`} hidden={true}>
                        <p>{`Blog`}</p>
                    </a>
                </div>
            </div>
        </div>
             
        {props.isSettings ? (showSettingsPopup &&
        <SettingsPopupRight show={showSettingsPopup} setShow={setShowSettingsPopup} onInvitesUpdate={(invites) => {
            props.setProfile(val => (val?.invites && invites) ? SetDict(val, ['invites'], invites) : val);
        }} invites={props.profile?.invites ?? null} profile={props.profile} isSettings={true}
        onEmailPrefsUpdate={(calendar, news, updates) => {
            props.setProfile(val => SetDict(val, [`emails_matches_calendar_reminders`], calendar));
            props.setProfile(val => SetDict(val, [`emails_platform_updates`], updates));
            props.setProfile(val => SetDict(val, [`emails_project_news`], news));
        }}/>) :
        (showSettingsPopup &&
            <SettingsPopupRight show={showSettingsPopup} setShow={setShowSettingsPopup} onInvitesUpdate={(invites) => {}} 
                                invites={null} profile={null} isSettings={false}/>)}
    </>)
}

const SiteCardSmall = (props) => {

    const  preview = [
        Plug1, 
        Plug2,
        Plug3,
    ][[0, 1, 2, 1, 0, 2, 0, 1, 2, 0, 1, 2, 1, 0, 2, 1, 0, 2][props?.orderNumber ?? 0]];
    const [ scrollAnswers, setScrollAnswers ] = useState(false);

    useEffect(() => {
        setScrollAnswers(false);
    }, [props.project])

    useEffect(() => {
        setScrollAnswers(false);
    }, [props.hidden])

    useEffect(() => {
        document.querySelectorAll(`[data-lines]`)
        .forEach(elem => {
            elem.scrollTo({
                top: 0,
                behavior: 'instant',
            })
        })
    }, [scrollAnswers])

    return (
        <div className={`block p-2 b-2 ${props.className}`} 
             style={{
                ...props.style,
                width: props.desktop ? 263 : `100%`,
            }} hidden={props.hidden} id={`project-card-${props.project?.id}`}>
            <div className={`block w-flex b-1 mb-2 row top right`}
                 style={{
                    backgroundImage: `url(${props.project?.preview ? MakeProperURL(props.project?.url, props.project?.preview) : preview})`,
                    aspectRatio: 2,
                 }} data-color={`black`}>
            </div>

            <div className={`w-flex column left`}>
                <div className={`w-flex`} style={{height: 170}}>
                    <p data-size={8} className={`semibold seminarrow mb-1`} data-color={`black`} data-lines={2}>
                        {props.project?.title}
                    </p>
                    <p data-size={6} data-lines={5} data-color={'black-40-opacity'}>
                        {(props.project?.description ?? '').trim().split('\n').map((elem, index) => {return ( elem ?
                            <span key={index} className={`article`}>
                                {elem}
                            </span> : null
                        )})}
                    </p>
                </div>
                <a className={`row left nowrap btn-circled-24 ${props.mobile ? `mobile` : `desktop`} p-left w-flex`} 
                   href={MakeValidURL(props.project?.url)} target={`_blank`} onClick={e => { e.stopPropagation() }}>
                    <img src={LinkBlack} alt={``}></img>
                    <p className={`ellipsis col-7`} style={{fontSize: 14}} data-color={`black`}>
                        {MakeNiceURL(props.project?.url)}
                    </p>
                </a>
            </div>
        </div>)
}

const Loading = (props) => {
    return (
        <svg className={`loading`} width={props.width} height={props.height}>
            <g fill="none"
                strokeWidth="10%">
                <circle r="40%" cx="50%" cy="50%"
                        stroke={props.color}
                        // strokeDasharray={`220% 230%`}
                        strokeDasharray={`255% 255%`}
                        strokeLinecap="round"/>
            </g>
        </svg>
)}

const Edit = (props) => {
    return (
        <svg className={props.className}>
            <path fill={'none'} stroke={props?.color ?? '#000000'} strokeWidth={2} 
                  className={props.hover ? 'hover-img' : ''}
                  d="M14.8,2.4c0.4-0.4,1-0.4,1.4,0l5.2,5.2c0.4,0.4,0.4,1,0,1.4L12.5,18c-0.2,0.2-0.4,0.3-0.6,0.3l-6.1,0.8  c-0.7,0.1-1.2-0.5-1.1-1.1l0.8-6.1c0-0.2,0.1-0.4,0.3-0.6L14.8,2.4z"/>
            <path fill={'none'} stroke={props?.color ?? '#000000'} strokeWidth={2}  
                  className={props.hover ? 'hover-img' : ''}
                  d="M16.5,7.5L10,14"/>
        </svg>
    )
}

const AngleRight = (props) => {
    return (
        <svg className={props.className} style={{width: '10px', height: '12px'}}>
            <path data-color={props['data-color']} className={props.hover ? 'hover-img' : ''}
            d="M3.4,11.7l-1.4-1.4L6.2,6L1.9,1.7l1.4-1.4L9.1,6L3.4,11.7z"/>
        </svg>
    )
}

const CardMatchesVideo = (props) => {
    return (
        <div className={`row center w-flex noscroll py-1`}>
            <div className={`video h-5 w-5 ${props.className}`}>
                <div className={`video-1 row logo medium nogap`}> 
                    <NewAvatar size={5} type={props.type ?? ['coral', 'bright-pink']} name={props.name ?? `A J`} avatar={props.avatar} className={`circled`}/>
                </div> 
                <div className={`video-2 row logo medium nogap`}> 
                    <NewAvatar size={5} type={4} name={`V A`} className={`circled`}/>
                </div> 
                <div className={`video-3 row logo medium nogap`}> 
                    <NewAvatar size={5} type={props.type ?? ['coral', 'bright-pink']} name={props.name ?? `A J`} avatar={props.avatar} className={`circled`}/>
                </div> 
                <div className={`video-4 row logo medium nogap`}> 
                    <NewAvatar size={5} type={9} name={`O P`} className={`circled`}/>
                </div>
                <div className={`video-5 row logo medium nogap`}> 
                    <NewAvatar size={5} type={4} name={`V A`} className={`circled`}/>
                </div> 
                <div className={`video-6 row logo medium nogap`}> 
                    <NewAvatar size={5} type={9} name={`O P`} className={`circled`}/>
                </div>
            </div>
        </div>
    )
}

const CardMatchesVideoMobile = (props) => {
    return (
        <div className={`row center w-flex noscroll`}>
            <div className={`video h-5 w-5 ${props.className}`}>
                <div className={`mobile-video-1 row logo medium nogap h-5 w-5`}> 
                    <NewAvatar size={5} type={props.type ?? ['coral', 'bright-pink']} name={props.name ?? `A J`} avatar={props.avatar}/>
                </div> 
                <div className={`mobile-video-2 row logo medium nogap h-5 w-5`}> 
                    <NewAvatar size={5} type={4} name={`V A`}/>
                </div> 
                <div className={`mobile-video-3 row logo medium nogap h-5 w-5`}> 
                    <NewAvatar size={5} type={props.type ?? ['coral', 'bright-pink']} name={props.name ?? `A J`} avatar={props.avatar}/>
                </div> 
                <div className={`mobile-video-4 row logo medium nogap h-5 w-5`}> 
                    <NewAvatar size={5} type={9} name={`O P`}/>
                </div>
                <div className={`mobile-video-5 row logo medium nogap h-5 w-5`}> 
                    <NewAvatar size={5} type={4} name={`V A`}/>
                </div> 
                <div className={`mobile-video-6 row logo medium nogap h-5 w-5`}>
                    <NewAvatar size={5} type={9} name={`O P`}/> 
                </div>
            </div>
        </div>
    )
}

const NewAvatar = (props) => {
    const [ letters, setLetters ] = useState(Capitals(props.name));

    const [colors, setColors ] = useState(['gray', 'black', 'gray', 'black']);

    useEffect(() => {
        if (typeof props.type === 'number' || props.type === null) {
            setColors([
                ProfileStyles[props?.avatar || props?.type > 1 ? (props?.type ?? 2) : 2].colorOne, 
                ScreenColorsDict[ProfileStyles[props?.avatar || props?.type > 1 ? (props?.type ?? 2) : 2].colorOne][1], 
                ProfileStyles[props?.avatar || props?.type > 1 ? (props?.type ?? 2) : 2].colorTwo,
                ScreenColorsDict[ProfileStyles[props?.avatar || props?.type > 1 ? (props?.type ?? 2) : 2].colorTwo][1],
            ]);
        }
        else if (typeof props.type === 'object' && props.type?.length > 1) {
            setColors([
                props.type[0], 
                props.type[2] ?? ScreenColorsDict[props.type[0]][1], 
                props.type[1],
                props.type[3] ?? ScreenColorsDict[props.type[1]][1],
            ]);
        }
    }, [props.type, props.avatar])

    useEffect(() => {
        setLetters(Capitals(props.name))
    }, [props.name])

    return (
        <div className={`row logo block noshrink nogap ${props.className} size-${props.size}`} 
             style={{
                ...props.style, width: props.customStyle?.width,
                backgroundImage: `url(${props?.avatar ?? ''})`,
                borderRadius: props.customStyle?.borderRadius ? props.customStyle?.borderRadius + 1 : undefined,
            }} data-color={`gray`} id={props.id} onClick={() => {
                if (typeof props.onClick === `function`) {
                    props.onClick();
                }
            }}>
            <div className={`logo-left block column top py not-active`} data-color={colors[0]}
                 style={props.customStyle ? {
                    borderRadius: `${props.customStyle?.borderRadius}px 0 0 ${props.customStyle?.borderRadius}px`
                 } : {}}>
                {(props.type !== 0 || !props.avatar) && (props.customStyle ? 
                <p style={{fontSize: props.customStyle?.fontSize}} className={`semibold seminarrow not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p> : <>
                {!(props.size) &&
                <p data-size={1} className={`semibold not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 11) &&
                <p data-size={1} className={`semibold not-active`}
                    data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 9) &&
                <p style={{fontSize: 76}} className={`semibold not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 8) &&
                <p data-size={2} className={`semibold not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 7) &&
                <p style={{fontSize: 58}} className={`semibold seminarrow not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 6) &&
                <p data-size={3} className={`semibold not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 5) &&
                <p data-size={4} className={`semibold not-active seminarrow`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 4) &&
                <p data-size={6} className={`semibold not-active narrow`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 3) &&
                <p data-size={7} className={`semibold narrow not-active`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 2) &&
                <p data-size={7} className={`semibold narrow not-active`}
                    data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}
                {!(props.size - 1) &&
                <p style={{fontSize: 8}} className={`semibold not-active narrow`}
                   data-color={props.type === 9 ? `white` : colors[1] ?? null}>{letters[0]}</p>}</>)}
            </div>
            <div className={`logo-right block column bottom py not-active`} data-color={colors[2]}
                 style={props.customStyle ? {
                    borderRadius: `0 ${props.customStyle?.borderRadius}px ${props.customStyle?.borderRadius}px 0`
                 } : {}}>
                {(props.type !== 0 || !props.avatar) && (props.customStyle ? 
                <p style={{fontSize: props.customStyle?.fontSize}} className={`semibold seminarrow not-active`}
                   data-color={props.type === 9 ? `white` : colors[3] ?? null}>{letters[1]}</p> : <>
                {!(props.size) &&
                <p data-size={1} className={`semibold not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 11) &&
                <p data-size={1} className={`semibold not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 9) &&
                <p style={{fontSize: 76}} className={`semibold not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 8) &&
                <p data-size={2} className={`semibold not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 7) &&
                <p style={{fontSize: 58}} className={`semibold seminarrow not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 6) &&
                <p data-size={3} className={`semibold not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 5) &&
                <p data-size={4} className={`semibold not-active seminarrow`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 4) &&
                <p data-size={6} className={`semibold not-active narrow`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 3) &&
                <p data-size={7} className={`semibold narrow not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 2) &&
                <p data-size={7} className={`semibold narrow not-active`} data-color={colors[3]}>{letters[1]}</p>}
                {!(props.size - 1) &&
                <p style={{fontSize: 8}} className={`semibold not-active narrow`} data-color={colors[3]}>{letters[1]}</p>}</>)}
           </div>
           {props.status ? 
           <div className={`cover block not-active`} data-color={props.status} 
                style={{opacity: 0.4, borderRadius: props.customStyle?.borderRadius}}></div> : 
           <div className={`cover block not-active`} data-color={`white-10-opacity`}
                style={{borderRadius: props.customStyle?.borderRadius}}></div>}
        </div>
    )
}

const SmallUserBlockHorizontal = (props) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);

    return (
        <div className={`py-${props.mobile ? 2 : 3} px${props.mobile ? '' : `-1`} clickable row top nowrap gap-2 ${props.className}`} onClick={() => {
                if (typeof props.onClick === 'function') {
                    props.onClick();
                } else {
                    GoTo(`/profile/${props.profile?.id}`);
                }
            }}>
            <NewAvatar size={7} name={props.profile?.name}
                       avatar={props.profile?.avatar?.link} type={props.profile?.avatar?.style}/>
            <div className={`w-flex column left`} style={{minHeight: 123}}>
                <div>
                    <p data-size={5} className={`semibold seminarrow w-flex`} 
                        data-color={`black`} data-lines={1}>
                        {props.profile?.name}
                    </p>
                    <p data-size={6} className={`mb w-flex`} 
                        data-color={`black-40-opacity`} data-lines={1}>
                        {props.profile?.occupation}
                    </p>
                    <p data-size={6} className={`mb-2 w-flex`} data-lines={4} data-color={`black`}>
                        {props.profile?.about.trim().split(`\n`).map((elem, number) => {return (elem ? <Fragment key={number}>
                            {elem}<br/>
                        </Fragment> : null)})}
                    </p>
                </div>
                {props.invites ?
                <button className={`block btn-circled-32 ${props.mobile ? `mobile` : `desktop`}`} 
                        data-color={`black`} onClick={e => {
                            e.stopPropagation();
                            if (typeof props.onInvite === `function`) {
                                props.onInvite();
                            }
                        }}>
                    <p>{`Invite`}</p>
                </button> :
                <div className={`row left nowrap w-flex`}>
                    <button className={`block btn-circled-32 ${props.mobile ? `mobile` : `desktop`} centered w-6`} 
                            data-color={`light-gray`} 
                            onClick={e => {
                                e.stopPropagation();
                                Subscribe(
                                    cookie[`user_id_cookie`],
                                    props.profile?.id,
                                    !props.profile?.followed,
                                    () => {
                                        props.setRecommendations((val) => SetDict(val, [props.index, 'user', 'followed'], !props.profile?.followed));
                                        if (typeof props.onSubscribe === 'function') {
                                            props.onSubscribe();
                                        }
                                    }
                                )
                            }} id={`${props.profile?.followed ? `unfollow` : `follow`}-user-btn`}>
                        <p>{props.profile?.followed ? `Unfollow` : `Follow`}</p>
                    </button>
                    <button className={`block btn-circled-32 ${props.mobile ? `mobile` : `desktop`} centered w-6`} data-color={`black`}
                            onClick={e => {
                                e.stopPropagation()
                                if (typeof props.onCanHelp === `function`) {
                                    props.onCanHelp();
                                }
                            }} disabled={props.canHelpDisabled}>
                        <p>{`Start chat`}</p>
                    </button>
                </div>}
            </div>
        </div>
    )
}

const SmallUserBlock = (props) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const context = useContext(ProfileContext);

    return (
        <div className={`block b-3 p-2 ${!props.invites ? `clickable` : ``} ${props.className}`} onClick={() => {
                if (typeof props.onClick === 'function') {
                    props.onClick();
                } else {
                    GoTo(`/profile/${props.profile?.id}`);
                }
            }} style={{display: `block`}} data-border={`gray-1`}>
            <NewAvatar size={9} className={`mb-2 circled`} name={props.profile?.name}
                       avatar={props.profile?.avatar?.link} type={props.profile?.avatar?.style}/>
            <div style={{width: 198, height: 61}}>
                <p data-size={5} className={`semibold seminarrow`} 
                    data-color={`black`} data-lines={1}>
                    {props.profile?.name ?? props.profile?.email}
                </p>
                <p data-size={6} className={`mb-2`} hidden={props.invites}
                    data-color={`black-40-opacity`} data-lines={2}>
                    {props.profile?.occupation}
                </p>
            </div>
            {props.invites ?
            <button className={`block btn-circled-40 ${props.mobile ? `mobile` : `desktop`} w-flex centered`} 
                    data-color={`black`} onClick={e => {
                        e.stopPropagation();
                        if (typeof props.onInvite === `function`) {
                            props.onInvite();
                        }
                    }} disabled={!context?.isSubscriptionActive}>
                <p className={`semibold`}>{`Invite`}</p>
            </button> : (true ? 
            <div className={`row left w-flex`} style={{overflow: `hidden`, height: 40, paddingTop: 16, maxWidth: 198}}>
                {(props.profile?.industry ?? []).map((elem, index) => {return (
                    <div className={`block label btn-circled-24 mobile`} key={index} data-border={true}
                            style={{border: `solid 1px ${CreateHSLColor(elem)}`}}>
                        <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                    </div>
                )})}
            </div> :
            <div className={`pt-1 row w-flex nowrap`}>
                <button className={`block btn-circled-32 ${props.mobile ? `mobile` : `desktop`} centered`} 
                        data-color={`light-gray`} 
                        onClick={e => {
                            e.stopPropagation();
                            Subscribe(
                                cookie[`user_id_cookie`],
                                props.profile?.id,
                                !props.profile?.followed,
                                () => {
                                    props.setRecommendations((val) => SetDict(val, [props.index, 'user', 'followed'], !props.profile?.followed));
                                    props.setSearchData((val) => SetDict(val, ['users', props.index, 'followed'], !props.profile?.followed));
                                    if (typeof props.onSubscribe === 'function') {
                                        props.onSubscribe();
                                    }
                                }
                            )
                        }} id={`${props.profile?.followed ? `unfollow` : `follow`}-user-btn`}
                        disabled={!context?.isSubscriptionActive}>
                    <p>{props.profile?.followed ? `Unfollow` : `Follow`}</p>
                </button>
                <button className={`block btn-circled-32 ${props.mobile ? `mobile` : `desktop`} p-both centered`} 
                        data-color={`black`} 
                        onClick={e => {
                            e.stopPropagation();
                            if (typeof props.onCanHelp === `function`) {
                                props.onCanHelp();
                            } else {
                                context.setWishToHelpRequest1({user_id: props.profile?.id});
                                context.setWishToHelpPopup1(true);
                            }
                        }} id={`chat-with-user-btn`} disabled={!context?.isSubscriptionActive}>
                    <img src={ChatWhite} alt={``} className={`w-3`}></img>
                </button>
            </div>)}
        </div>
    )
}

const LargeAvatar = (props) => {

    const [ initCoords, setInitCoords ] = useState()

    const [ show, setShow ] = useState(false);

    useEffect(() => {
        if (props.avatar) {
            const avatar = document.querySelector(`#avatar-btn-to-large-${props?.avatar?.id ?? 0}`);
            setInitCoords({
                x: avatar ? avatar.getBoundingClientRect().x : window.innerWidth,
                y: avatar ? avatar.getBoundingClientRect().y : window.innerHeight,
                link: props?.avatar?.link, name: props?.avatar?.name,

            })
            document.documentElement.style.overflowY = 'hidden';
        } else {
            setShow(false);
            document.documentElement.style.overflowY = null;
        }
    }, [props.avatar])

    useEffect(() => {
        setTimeout(() => {
            setShow(val => !!initCoords ? true : val);
        }, 200)
    }, [initCoords]);

    return (
        <div style={{
            transition: `background-color 0.2s ease-in-out`,
            backgroundColor: show ? `#00000066` : `#00000000`,
            pointerEvents: show ? `auto` : `none`,
            position: `fixed`,
            left: 0, right: 0, bottom: 0, top: 0,
            zIndex: 100,
        }} onClick={() => { props.setAvatar(null) }}> 
            <div style={{
                transition: `left 0.2s ease-in-out, top 0.2s ease-in-out, opacity 0.2s ease-in-out`,
                position: 'relative',
                opacity: show ? 1 : 0,
                left: show ? window.innerWidth / 2 - (props.isMobile ? 128 : 209) : (initCoords?.x ?? window.innerWidth), 
                top: show ? window.innerHeight / 2 - (props.isMobile ? 128 : 209) : (initCoords?.y ?? window.innerHeight), 
            }} className={`row left top nogap nowrap w-${show ? (props.isMobile ? 8 : 9) : 4}`} onClick={e => { e.stopPropagation() }}>
                <NewAvatar size={show ? (props.isMobile ? 8 : 9) : 4} type={1}
                           name={initCoords?.name} avatar={initCoords?.link}/>
                <button className={`btn ${props.isMobile && 'mobile'}`} onClick={() => { props.setAvatar(null) }}>
                    <div className={`btn-symbol close filled w-3 h-3 b-2 centered neg-mt-3 meg-ml-3`} data-color={`white`}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </div>
                </button>
            </div>
        </div>
    )
}

const GoToDesktop = (props) => {
    return (
        <div className={`w-screen column`} style={{overflow: 'hidden'}}>
            <div className={`w-flex h-screen block pt-4 column`} data-color={`gray`}>
                <div className={`row left noscroll pl-3 w-flex`}>                  
                    <div className={`row left nogap nowrap noscroll osmos-logo`} style={{height: `30px`}}>
                        <img src={OsmosOBlack} alt={`O`} style={{height: 19}}></img>
                        <img src={OsmosSBlack} alt={`S`} style={{height: 30}}></img>
                        <img src={OsmosMBlack} alt={`M`} style={{height: 19}}></img>
                        <img src={OsmosOBlack} alt={`O`} style={{height: 11}}></img>
                        <img src={OsmosSBlack} alt={`S`} style={{height: 7}}></img>
                    </div>
                    <div className={`block`} data-color={'black'} style={{height: 30, width: 1}}></div>
                    <p data-size={6} className={`narrow semibold`} data-color={'black'}>{`Meet the right crowd.`}<br/>{`Get things done.`}</p> 
                </div>
                <div className={`w-flex column pt-5 h-flex`} style={{marginTop: `-50%`, marginBottom: `-50%`}}>
                    <div className={`w-flex neg-mb-4 neg-ml-5`}>
                        <NewAvatar type={['pink', 'green']} name={`F G`} size={6}/></div>
                    <div className={`w-flex row right neg-mb-4`}>
                        <NewAvatar type={['light-blue', 'yellow']} name={`F G`} size={6} className={`neg-mr-5`}/></div>
                    <div className={`w-flex row right pr-7 pb-3`}>
                        <NewAvatar type={['light-yellow', 'coral']} name={`F V`} size={5}/></div>
                    
                    <div className={`w-flex px-3`} style={{zIndex: 1}}>
                        <p data-size={6} className={`semibold mb w-flex center`}>{`The mobile version is in progress`}</p>
                        <p data-size={4} className={`w-flex center`}>{`Go ahead and open up the same link on your desktop.`}</p>
                    </div>

                    <div className={`w-flex pl-6 pb-4 pt-5`}>
                        <NewAvatar type={['light-pink', 'coral']} name={`W H`} size={5}/></div>
                    <div className={`w-flex row right pr-4`}>
                        <NewAvatar type={['white', 'blue']} name={`R K`} size={5}/></div>
                    <div className={`w-flex neg-mb-4 pl-3`}>
                        <NewAvatar type={['white', 'pink']} name={`V N`} size={6}/></div>
                </div>
                <div></div>
            </div>
            <FooterDesktop isMobile={true}/>
        </div>
    )
}

const FooterDesktop = (props) => {
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);

    // const [ color, setColor ] = useState(props.color ?? ScreenColors[Math.floor(Math.random() * 3)][0]);
    const [ color, setColor ] = useState(props.color ?? `gray`);

    useEffect(() => {
        const btn = document.querySelector(`#navbar-button-color`);
        if (btn) {
            btn.setAttribute(`navbar-color`, {
                'light-blue': `blue`,
                'light-yellow': `green`,
                'light-pink': `pink`,
                'gray': `black`,
            }[color] ?? ``);
            btn.click();
        } else {
            const newBtn = document.querySelector(`#top-bar-button-color`);
            if (newBtn) {
                newBtn.setAttribute(`top-bar-color`, {
                    'light-blue': `blue`,
                    'light-yellow': `green`,
                    'light-pink': `pink`,
                    'gray': `black`,
                }[color] ?? ``);
                newBtn.click();
            }
        }
    }, [color])

    return (<>
        <div className={`w-flex`} style={{height: 58}}></div>
        <div className={`block block-absolute-bottom content-box w-flex`} data-color={color} style={{
                marginLeft: props.isMobile ? 0 : `min(0px, 50% - 50vw)`,
                marginRight: props.isMobile ? 0 : `min(0px, 50% - 50vw)`,
                width: `100vw`,
                height: 58,
                transitionDuration: `0s`,
             }}>
        </div>
        <div className={`w-flex px-${props.isMobile ? 3 : 5} md-px-3 ${props.className} block-absolute-bottom`} 
             style={{paddingTop: 17, paddingBottom: 17}}>
            <div className={`row w-flex`}>
                <div className={`row center nogap noscroll osmos-logo nowrap`} 
                     style={{height: `24px`, opacity: globalContext.community !== undefined ? 1 : 0}}>
                    {!!globalContext.community && !props.hideCommunity ?
                    <img src={CommunityLogo[globalContext.community][props.innerColor ?? ScreenColorsDict[color][1]]} alt=""
                        style={{height: 24}}></img> : <>
                    <img src={LogoLetters[props.innerColor ?? ScreenColorsDict[color][1]].O} alt={`O`} 
                            style={{height: props.isMobile ? 15 : 15}}></img>
                    <img src={LogoLetters[props.innerColor ?? ScreenColorsDict[color][1]].S} alt={`S`} 
                            style={{height: props.isMobile ? 24 : 24, marginRight: props.isMobile ? 0 : 1}}></img>
                    <img src={LogoLetters[props.innerColor ?? ScreenColorsDict[color][1]].M} alt={`M`} 
                            style={{height: props.isMobile ? 15 : 15}}></img>
                    <img src={LogoLetters[props.innerColor ?? ScreenColorsDict[color][1]].O} alt={`O`} 
                            style={{height: props.isMobile ? 9 : 9}}></img>
                    <img src={LogoLetters[props.innerColor ?? ScreenColorsDict[color][1]].S} alt={`S`} 
                            style={{height: props.isMobile ? 6 : 6}}></img>                        
                    </>}
                    <button style={{height: 0, width: 0, pointerEvents: `none`}} onClick={e => {
                        if (e.target.getAttribute(`footer-color`) && !props.color) {
                            setColor(e.target.getAttribute(`footer-color`)); 
                        }
                    }} id={`footer-button-color`}></button>
                </div>
                {/* <p className={`neg-my-2`} hidden={!(/(?:^qa\.)|(?:^localhost:)/).test(window.location.host)}>
                    {`OAuth client ID:`}<br/>
                    {`14000969611-greqvb7bbldfh7p5upi9l6phb7s7vacd.apps.googleusercontent.com`}
                </p> */}
                {props.agreement ? 
                <a className={`block btn-circled-24 ${props.isMobile ? 'mobile' : 'desktop'} row`} data-color={`transparent`} href={`/agreement`}
                    hidden={!!globalContext.community}>
                    <p data-size={6} data-color={props.innerColor ?? ScreenColorsDict[color][1]}>{`User agreement`}</p>
                </a>
                : (props.links ?
                <div className={`row gap-3 nowrap left`}>
                    <a className={`btn row`} href={`https://www.linkedin.com/company/osmos-social`}>
                        <img src={(props.innerColor ?? ScreenColorsDict[color][1]) === `white` ? LinkedInWhite : LinkedInBlack} alt={``} className={`w-3`}></img>
                    </a>
                    <a className={`btn row`} href={`https://twitter.com/Osmos_social`}>
                        <img src={(props.innerColor ?? ScreenColorsDict[color][1]) === `white` ? XWhite : XBlack} alt={``} className={`w-3`}></img>
                    </a>
                </div> :
                <button className={`block btn`} data-color={`transparent`} onClick={e => {
                    e.stopPropagation();
                    if (typeof props.toSupport == `function`) {
                        props.toSupport();
                    } else {
                        navigate(`/profile/settings?target=support`);
                    }
                }}>
                    <p data-size={6} className={`narrow regular`} data-color={props.innerColor ?? ScreenColorsDict[color][1]}>
                        <FormattedMessage id="settings_support"/>
                    </p>
                </button>)}
            </div>
        </div>
    </>)
}

const AnimatedLogo = (props) => {  
    const [ color, setColor ] = useState(props.color ?? `black`);
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const navigate = useNavigate();

    const [ logoStep, setLogoStep ] = useState(1);
    const [ enableAnimation, setEnableAnimation ] = useState(true);

    const sizes = [ 
        `${Math.floor((props.size ?? 32) / 6.55)}px`, 
        `${Math.floor((props.size ?? 32) / 4.1)}px`, 
        `${Math.floor((props.size ?? 32) / 2.56)}px`, 
        `${Math.floor((props.size ?? 32) / 1.6)}px`, 
        `${Math.floor((props.size ?? 32) / 1)}px`, 
        `${Math.floor((props.size ?? 32) / 1.6)}px`, 
        `${Math.floor((props.size ?? 32) / 2.56)}px`, 
        `${Math.floor((props.size ?? 32) / 4.1)}px`, 
        `${Math.floor((props.size ?? 32) / 6.55)}px` 
    ]
    const states = [ 4, 3, 2, 1, 0, 1, 2, 3 ]

    useEffect(() => {
        if (enableAnimation && !props.autoAnimation) {
            returnLogo(logoStep);  
        }      
    }, [logoStep, enableAnimation])

    useEffect(() => {
        if (props.autoAnimation) {
            autoStep(0);
        }
    }, [])

    const autoStep = (n) => {
        if (n < 5) {
            setTimeout(() => {
                setLogoStep(val => (val + 1) % 5);
                autoStep(n + 1);
            }, 500);
        }
    }

    const returnLogo = (val) => {
        if (val < 1) {
            setTimeout(() => {
                setLogoStep((val + 1) % 5);
            }, 300);
        } else if (val > 1) {
            setTimeout(() => {
                setLogoStep((val + 4) % 5);
            }, 300);
        }
    }

    return (
        <div className={`row center nogap ${props.withMoto || props.size ? "" : 'w-6'} osmos-logo-desktop nowrap ${props.className} clickable`} style={{...props.style, height: `${props.size ?? 40}px`}} 
                onMouseEnter={() => { setEnableAnimation(false) }} onMouseLeave={() => { setEnableAnimation(true) }}
                onClick={e => {
                    e.stopPropagation();
                    if (typeof props.onClick === `function`) {
                        props.onClick()
                    } else {
                        navigate(cookie[`user_id_cookie`] ? `/profile` : `/`);
                    }
                }}>
            <img src={LogoLetters[color].O} alt={`O`} style={{
                height: sizes[states[logoStep] % 8],
                WebkitFilter: `drop-shadow(0px 0px 20px ${props.shadowColor})`,
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(0) }}></img>
            <img src={LogoLetters[color].S} alt={`S`} style={{
                height: sizes[(states[logoStep] + 1) % 8], marginRight: 1,
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(1) }}></img>
            <img src={LogoLetters[color].M} alt={`M`} style={{
                height: sizes[(states[logoStep] + 2) % 8],
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(2) }}></img>
            <img src={LogoLetters[color].O} alt={`O`} style={{
                height: sizes[(states[logoStep] + 3) % 8],
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(3) }}></img>
            <img src={LogoLetters[color].S} alt={`S`} style={{
                height: sizes[(states[logoStep] + 4) % 8],
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(4) }}></img>

            <button style={{height: 0, width: 0, pointerEvents: `none`}} onClick={e => {
                e.stopPropagation();
                if (e.target.getAttribute(`navbar-color`)) {
                    setColor(e.target.getAttribute(`navbar-color`)); 
                }
            }} id={props.local ? `logo` : `navbar-button-color`}></button>

            {props.withMoto && <>            
            <div className={`block mx`} data-color={ScreenColorsDict[props?.color ?? 'light-gray'][1] === `white` ? `white` : color} style={{height: 44, width: 1}}></div>
            <p className={`text-6 semibold`} data-color={ScreenColorsDict[props?.color ?? 'light-gray'][1] === `white` ? `white` : color}>
                {`Meet the right crowd.`}<br/>{`Get things done.`}
            </p></>}
        </div>
    )
}

const LeftNavBar = (props) => {
    const navigate = useNavigate();
    const context = useContext(ProfileContext);

    const [ showNotif, setShowNotif ] = useState(false);  

    return (
        <div style={{...Sticky(14, 1)}} className={`${props.className} column top center`} id={`navbar`}>
            {props.withLogo &&
            <AnimatedLogo className={`pb mb-3`}/>}

            <div className={`block p b-3 mb-5`} hidden={props.hideNavigation}>
                <button className={`block btn-menu p-2 column center w-6 h-6 mb`} data-color={!props.other && !(context.page - 1) ? `blue` : `white`}
                        onClick={() => {
                            if (props.other) {
                                navigate(`/profile/explore`);
                            } else {
                                context.setPage(1, 1);
                                context.setTab(1, 1);
                            }
                        }} id={`navbar-feed-button`} data-disabled={!context.isSubscriptionActive}>                        
                    <div className={`row left`}>
                        <img className={`w-3 hover`} src={!props.other && !(context.page - 1) ? ListWhite : ListBlack} alt={`p`}></img>
                        {!!props.profile?.feed_number && 
                        <div className={`block h-3 min-w-3 b-2 centered px-1`} data-color={`coral`}>
                            <p className={`text-6 light not-active`}>{props.profile?.feed_number > 99 ? '99+' : props.profile?.feed_number}</p>
                        </div>}
                    </div>
                    <p className={`mt-1 custom-hover`} data-color={!props.other && !(context.page - 1) ? `white` : `black`}>{`Feed`}</p>
                </button>

                <button className={`block btn-menu p-2 column center w-6 h-6 mb`} data-color={!props.other && !(context.page - 2) ? `blue` : `white`}
                        onClick={() => {
                            if (props.other) {
                                navigate(`/profile/chat`);
                            } else {
                                context.setPage(2, 0);
                                context.setTab(0, 2);
                            }
                        }}>                        
                    <div className={`row left`}>
                        <img className={`w-3 hover`} src={!props.other && !(context.page - 2) ? ChatWhite : ChatBlack} alt={`p`}></img>
                        {!!props.profile?.chat_number && 
                        <div className={`block h-3 min-w-3 b-2 centered px-1`} data-color={`light-blue`}>
                            <p className={`text-6 light not-active`} data-color={`white`}>
                                {props.profile?.chat_number > 99 ? '99+' : props.profile?.chat_number}
                            </p>
                        </div>}
                    </div>
                    <p className={`mt-1 custom-hover`} data-color={!props.other && !(context.page - 2) ? `white` : `black`}>{`Chats`}</p>
                </button>

                <button className={`block btn-menu p-2 column center w-6 h-6 mb`} data-color={!props.other && !(context.page - 4) ? `blue` : `white`}
                        onClick={() => {
                            if (props.other) {
                                navigate(`/profile/requests`);
                            } else {
                                context.setPage(4, 0);
                                context.setTab(0, 4);
                            }
                        }} data-disabled={!context.isSubscriptionActive}>                        
                    <div className={`row left`}>
                        <img className={`w-3 hover`} src={!props.other && !(context.page - 4) ? DocWhite : DocBlack} alt={`p`}></img>
                    </div>
                    <p className={`mt-1 custom-hover`} data-color={!props.other && !(context.page - 4) ? `white` : `black`}>{`Requests`}</p>
                </button>
                
                <button className={`block btn-menu p-2 column center w-6 h-6 mb`} data-color={!props.other && !context.page ? `blue` : `white`}
                        onClick={() => {
                            if (props.other) {
                                navigate(`/profile/${props.profile?.id}`);
                            } else {
                                context.setPage(0, 0);
                                context.setTab(0, 0);
                            }
                        }}> 
                    {props.profile ? 
                    <NewAvatar size={4} type={props.profile?.avatar?.style ?? 0} name={props.profile?.name}
                               avatar={props.profile?.avatar?.link ?? ``}/> : 
                    <div className={`block h-4 w-4 b-1`} data-color={!props.other && !context.page ? `white-10-opacity` : `gray`}></div>}                            
                    <p className={`mt-1 custom-hover`} data-color={!props.other && !context.page ? `white` : `black`}>{`Profile`}</p>
                </button>

                <button className={`block btn-menu p-2 column center w-6 h-6`} data-color={!props.other && !(context.page - 3) ? `blue` : `white`}
                        onClick={() => {
                            if (props.other) {
                                navigate(`/profile/invites`);
                            } else {
                                context.setPage(3, 0);
                                context.setTab(0, 3);
                            }
                        }} 
                        style={{position: `relative`}}
                        onMouseEnter={() => { 
                            // setShowNotif(true) 
                        }} onMouseLeave={() => { 
                            setShowNotif(false) 
                        }} data-disabled={!context.isSubscriptionActive}>  

                    {showNotif &&
                    <div className={`block b-3 p-3 neg-mr-8`} data-color={`dark-gray`}
                         style={{position: `absolute`, right: -70, zIndex: 150}}>
                        <p style={{fontSize: 16}} className={`semibold mb-1 w-8 not-active`}>{`Invites are currently under construction`}</p>
                        <p data-size={6} className={`not-active`}>{`They’ll be back soon.`}</p>
                    </div>} 

                    <div className={`row left custom-hover`}>
                        <img className={`w-3 hover`} src={!props.other && !(context.page - 3) ? HumansWhite : HumansBlack} alt={`p`}></img>
                    </div>
                    <p className={`mt-1 custom-hover`}
                       data-color={!props.other && !(context.page - 3) ? `white` : `black`}>
                        {`Invites`}
                    </p>
                </button>
            </div>           
        </div>
    )
}

const HintBlock = (props) => {
    const getPosition = (position) => {
        switch (position) {
            case `top-left`:
                return {
                    tail: {
                        top: -24,
                        left: 0,
                        transform: `rotate(-90deg)`
                    },
                    hint: {
                        borderRadius: `0 24px 24px 24px`,
                    },
                    tailDirection: TailTRGreen,
                }
            case `top-right`:
                return {
                    tail: {
                        top: -24,
                        right: 0,
                        transform: `rotate(90deg)`
                    },
                    hint: {
                        borderRadius: `24px 0 24px 24px`,
                    },
                    tailDirection: TailTLGreen,
                }
            case `bottom-right`:
                return {
                    tail: {
                        bottom: -24,
                        right: 0,
                        transform: `rotate(90deg)`
                    },
                    hint: {
                        borderRadius: `24px 24px 0 24px`,
                    },
                    tailDirection: TailTRGreen,
                }
            case `bottom-left`:
                return {
                    tail: {
                        bottom: -24,
                        left: 0,
                        transform: `rotate(-90deg)`
                    },
                    hint: {
                        borderRadius: `24px 24px 24px 0`,
                    },
                    tailDirection: TailTLGreen,
                }
            case `left-top`:
                return {
                    tail: {
                        top: 0,
                        left: -24,
                        transform: `rotate(0deg)`
                    },
                    hint: {
                        borderRadius: `0 24px 24px 24px`,
                    },
                    tailDirection: TailTLGreen,
                }
            case `left-bottom`:
                return {
                    tail: {
                        bottom: 0,
                        left: -24,
                        transform: `rotate(180deg)`
                    },
                    hint: {
                        borderRadius: `24px 24px 24px 0`,
                    },
                    tailDirection: TailTRGreen,
                }
            case `right-top`:
                return {
                    tail: {
                        top: 0,
                        right: -24,
                        transform: `rotate(0deg)`
                    },
                    hint: {
                        borderRadius: `24px 0 24px 24px`,
                    },
                    tailDirection: TailTRGreen,
                }
            case `right-bottom`:
                return {
                    tail: {
                        bottom: 0,
                        left: -24,
                        transform: `rotate(180deg)`
                    },  
                    hint: {
                        borderRadius: `24px 24px 0 24px`,
                    },
                    tailDirection: TailTLGreen,
                }
            default:
                return {
                    tail: {
                        display: `none`,
                        transform: `rotate(deg)`
                    },
                    hint: {
                        borderRadius: `24px`,
                    },
                    tailDirection: null,
                };
        }
    }

    const hiddenData = {
        'data-xs-hidden': props.breakSize === `xs` ? true : null,
        'data-sm-hidden': (!props.breakSize || props.size === `sm`) ? true : null,
        'data-md-hidden': props.breakSize === `md` ? true : null,
        'data-lg-hidden': props.breakSize === `lg` ? true : null,
        'data-xl-hidden': props.breakSize === `xl` ? true : null,
    }
    
    const hiddenData2 = {
        'data-xs-shown': props.breakSize === `xs` ? true : null,
        'data-sm-shown': (!props.breakSize || props.size === `sm`) ? true : null,
        'data-md-shown': props.breakSize === `md` ? true : null,
        'data-lg-shown': props.breakSize === `lg` ? true : null,
        'data-xl-shown': props.breakSize === `xl` ? true : null,
    }

    const tailWideStyle = getPosition(props.tailDirection ?? (props.left ? `right-top` : `left-top`)).tail;
    const tailWideStyleNarrow = getPosition(props.tailDirectionNarrow ?? props.tailDirection ?? (props.left ? `right-top` : `left-top`)).tail;
    const hintBorderRadius = getPosition(props.tailDirection ?? (props.left ? `right-top` : `left-top`)).hint;
    // const hintBorderRadiusNarrow = getPosition(props.tailDirectionNarrow ?? '').hint;
    const tailImg = getPosition(props.tailDirection ?? (props.left ? `right-top` : `left-top`)).tailDirection;
    const tailImgNarrow = getPosition(props.tailDirectionNarrow ?? props.tailDirection ?? (props.left ? `right-top` : `left-top`)).tailDirection;

    return (<>
        <div className={`hint-borders`}></div>
        <div className={`block p-3 b-3 block-hint${props.left ? `-left` : ``} block-${props.breakSize ?? `sm`}-hint${props.left ? `-left` : ``} ${props.show ? `` : `hint-hidden`}`} 
             id={props.id_} style={{
                borderRadius: hintBorderRadius.borderRadius,
                ...props.style,
             }} data-color={`green`}>
            <div style={{height: 0, width: `calc(100% + 48px)`, position: `relative`, top: -24, left: -24}}>                
                <img src={tailImg} alt={``} style={{
                    position: `absolute`,
                    width: 24,
                    height: 24, 
                    ...tailWideStyle,
                }} className={`hint-tail ${props.show ? `` : `hint-hidden`}`} {...hiddenData}></img>
                <img src={tailImgNarrow} alt={``} style={{
                    position: `absolute`,
                    width: 24, 
                    height: 24, 
                    ...tailWideStyleNarrow,
                }} className={`hint-tail ${props.show ? `` : `hint-hidden`}`} {...hiddenData2} data-hidden={true}></img>
            </div>
            <div className={`row left mb-1`}>
                <p data-size={5} className={`semibold seminarrow`}>{props.title}</p>
            </div>
            <p data-size={6}>
                {props.description}
            </p>
            <button className={`block btn-circled-32 desktop mt-2 hint-active-btn`} onClick={() => {
                if (typeof props.onClick === `function`) {
                    props.onClick();
                }
            }}>
                <p data-size={6} className={`semibold seminarrow`}>{props.buttonTitle ?? `Next`}</p>
            </button>
        </div> 
    </>)
}

const NotificationImg = (props) => {
    return (
        <div className={`${props.className} custom-hover`} style={{
            position: `relative`, ...props.style,
        }}>
            <img src={props.color === 'white' ? NotificationWhite : NotificationBlack} alt={``} className={`h-flex w-flex not-active`}></img>
            <div className={`block b-3 h-2 min-w-2 centered px not-active`} data-color={`coral`} style={{
                top: 0, right: -2, position: `absolute`,
            }} hidden={!props.number}>
                <p style={{fontSize: 12}} className={`not-active`}>{props.number > 99 ? `99+` : props.number}</p>
            </div>
        </div>
    )
}

const ShowNotificationsPopupButtonDesktop = (props) => {
    const context = useContext(ProfileContext); 
    const globalContext = useContext(GlobalContext);
    // const [ searchParams, setSearchParams ] = useSearchParams();

    const [ notificationPopup, setNotificationPopup ] = useState(null);
    // const [ openSearch, setOpenSearch ] = useState(false);
    // const [ openSearchData, setOpenSearchData ] = useState(false);
    // const navigate = useNavigate();

    // const [ timer, setTimer ] = useState();
    // const [ searchData, setSearchData ] = useState();

    // const searchInput = useRef();

    // const onClick = () => {
    //     setOpenSearchData(false);
    // }

    // useEffect(() => {
    //     if (openSearch && (searchData?.users?.length > 0 || localStorage.getItem(`search_history`))) {
    //         setTimeout(() => {
    //             setOpenSearchData(true);
    //         }, 300)
    //     } else {
    //         setOpenSearchData(false);
    //     }
    // }, [openSearch])

    // useEffect(() => {
    //     document.addEventListener('click', onClick, true);

    //     return () => {            
    //         document.removeEventListener('click', onClick, true);
    //     }
    // }, [])

    return (
        <div className={`row nowrap left noshrink ${props.className}`}>
            {/* <form className={`label block btn-circled-40 desktop p-left p-right row nowrap left`}
                  onSubmit={e => {
                    e.preventDefault();
                    try {
                        context.setGlobalSearch(e.target.search.value ?? "");
                        context.setPage(1, 1);
                        context.setTab(1, 1);
                    } catch (err) {
                        navigate(`/profile/explore?search=${encodeURIComponent(e.target.search.value)}`);
                    }
                    // navigate(`/profile/explore?search=${encodeURIComponent(e.target.search.value)}`);
                  }} style={{position: `relative`}} data-color={!context?.isSubscriptionActive ? 'gray' : null}>
                <input ref={searchInput} data-size={8} style={{
                    width: openSearch ? 240 : 0,
                    height: 24, backgroundColor: `transparent`,   
                    transition: `width 0.3s ease-in-out`,
                }} placeholder={`Search`} className={`neg-mr-1 pl`} name={`search`}
                onBlur={e => {
                    if (e.relatedTarget?.id !== `search-option` && !e.target.value?.length) {
                        clearTimeout(timer);
                        setOpenSearch(false);
                    }
                }} onChange={e => {
                    clearTimeout(timer);
                    if (!!e.target.value?.length) {
                        setTimer(setTimeout(() => {
                            SearchRequest(e.target?.value ?? "", 5, setSearchData);
                        }, 500))
                        setOpenSearchData(true);
                    } else {
                        setSearchData(undefined);
                    }
                }}></input>
                <img src={!context?.isSubscriptionActive ? LensWhite : LensBlack} alt={``} className={`w-3 clickable`} onClick={() => { 
                    if (!!context?.isSubscriptionActive) {
                        setOpenSearch(true);
                        if (searchInput.current) {
                            searchInput.current.focus();
                            searchInput.current.value = context.globalSearch ?? "";
                        }
                    }
                }}></img>

                <div className={`block py-2 b-3 max-h-8 y-auto box-shadow`} 
                     hidden={!(openSearch && openSearchData)}
                     style={{position: `absolute`, left: 0, right: 0, top: 44, zIndex: 2}}>
                    {searchData?.users?.length > 0 ? (searchData?.users ?? []).map((elem, index) => {return (
                        <a className={`btn desktop row left nowrap w-flex px-3 py hover-gray`} key={index} type={`button`}
                           href={`/profile/${elem.unique_id ?? elem.id}`} id={`search-option`} tabIndex={index}>
                            <p data-size={8}>{elem.name}</p>
                        </a>
                    )}) : JSON.parse(localStorage.getItem(`search_history`) ?? `[]`).map((elem, index) => {return (
                        <button className={`btn desktop row left nowrap w-flex px-3 py hover-gray`} key={index} type={`button`}
                                onClick={() => {
                                    searchInput.current.value = elem;
                                    try {
                                        context.setGlobalSearch(elem);
                                        context.setPage(1, 1);
                                        context.setTab(1, 1);
                                    } catch (err) {
                                        navigate(`/profile/explore?search=${encodeURIComponent(elem)}`);
                                    }
                                }} id={`search-option`} tabIndex={index}>
                            <img src={HistoryGray} alt={``} className={`w-3`}></img>
                            <p data-size={8}>{elem}</p>
                        </button>
                    )})}
                </div>
            </form> */}

            <a className={`block btn-circled-40 desktop p-left p-right row`}
               href={`/blog`} hidden={!!globalContext.community}>
                <img src={PageListBlack} alt={``} className={`w-3`}></img>
            </a> 

            <button className={`block btn-circled-40 desktop p-left p-right row noshrink`} 
                    hidden={props.hidden} disabled={props.disabled || !context?.isSubscriptionActive}
                    onClick={e => { 
                        setNotificationPopup((e.target).getBoundingClientRect());
                    }} data-color={!props.number ? undefined : `coral`}>
                {/* <NotificationImg className={`w-3 h-3`} number={props.number}
                                color={`black`}/> */}
                <img src={(props.disabled || !!props.number || !context?.isSubscriptionActive) ? NotificationWhite : NotificationBlack} alt={``} className={`h-3 w-3`}></img>
                <div className={`block b-3 h-3 min-w-3 centered px`} hidden={!props.number}>
                    <p data-size={6} className={`not-active`}>{props.number > 99 ? `99+` : props.number}</p>
                </div>
            </button>                   

            {!!notificationPopup && 
            <PopupNotifications show={!!notificationPopup}
                                setShow={show => { setNotificationPopup(val => show ? val : null) }}/>}
        </div>
    )
}

const ShowNotificationsPopupButtonMobile = (props) => {
    return (
        <button className={`block btn-circled-40 mobile p-left p-right row neg-my-2`} 
                hidden={props.hidden} disabled={props.disabled}
                onClick={e => { 
                    if (typeof props.onClick === `function`) {
                        props.onClick();
                    }
                }} data-color={!props.number ? undefined : `coral`}>
            {/* <NotificationImg className={`w-3 h-3`} number={props.number}
                            color={`black`}/> */}
            <img src={(props.disabled || !!props.number) ? NotificationWhite : NotificationBlack} alt={``} className={`h-3 w-3`}></img>
            <div className={`block b-3 h-3 min-w-3 centered px`} hidden={!props.number}>
                <p data-size={6} className={`not-active`}>{props.number > 99 ? `99+` : props.number}</p>
            </div>
        </button>
    )
}

const PreloaderLogo = (props) => {
    const [ hideLogo, setHideLogo ] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setHideLogo(true);
        }, 3000)
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        })
        
        document.documentElement.style.overflowY = props.shown ? 'hidden' : ``;
    }, [props.shown])

    return (
        <div className={`block w-screen h-screen centered`} data-color={`blue`}
             style={{
                position: `fixed`, top: 0, left: 0, zIndex: 100,
                transition: `opacity .5s ease-in-out`, opacity: props.shown ? 1 : 0,
                pointerEvents: props.shown ? `auto` : `none`,
            }}>
            <AnimatedLogo color={`white`} size={76} autoAnimation={true} style={{transition: `opacity .7s ease-in-out`, opacity: hideLogo ? 0 : 1}}/>
        </div>
    )
}

export const ProductHuntLabel = (props) => {
    return (
        <div className="column top" style={{
            position: 'fixed',
            right: 40, bottom: 40,
        }}>
            <img src={PHLogo} style={{width: 119, marginBottom: -54, position: "relative", zIndex: 2}} alt=""></img>
            <div className="block column top" style={{
                padding: 16, paddingTop: 54,
                border: "solid 2px #E86640",
                borderRadius: 8,
            }}>
                <a style={{color: "#E86640", fontSize: 12}} href={"https://www.producthunt.com/posts/osmos-2"} target="_blanc">
                    <span className="semibold seminarrow">{"Support our launch!"}</span>
                </a>
            </div>
        </div>
    )
}

export {
    TopBar,
    SmallUserBlock,
    SmallUserBlockHorizontal,
    TopBarDesktop,
    Loading,
    SiteCardSmall,
    CardMatchesVideo,
    CardMatchesVideoMobile,
    FooterDesktop,
    Edit,
    AngleRight,
    NewAvatar,
    GoToDesktop,
    LargeAvatar,
    LeftNavBar,
    HintBlock,
    NotificationImg,
    ShowNotificationsPopupButtonDesktop,
    ShowNotificationsPopupButtonMobile,
    AnimatedLogo,
    PreloaderLogo,
}